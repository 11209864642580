define(
  ({
    app_name: 'Elastic',
    none: 'Ei mitään',
    select: 'valitse...',
    cancel: 'Peruuta',
    drop_ship_instructions: 'Ainoastaan kotimaan toimitus. Ei toimitusta APO- tai FPO-osoitteisiin.',
    product_data_sheet_tab_technology: 'Teknologia',
    product_data_sheet_tab_overview: 'Yleiskatsaus',
    product_data_sheet_tab_details: 'Lisätiedot',
    cart_submit_order_dealer: 'Tee tilaus',
    cart_submit_order_rep: 'Tee tilaus',
    cart_submit_order: 'Lähetä tilaus',
    erp_order_number: 'ERP-tilaus #',
    sap_order_number: 'SAP-tilaus #',
    nda_explanation: 'NDA-selvennys.',
    available_as_of: 'Saatavilla <date />',
    terms_and_conditions_description: 'Aseta merkintä alle ilmaistaksesi, että olet lukenut ja hyväksyt <a href="###" target="_blank">käyttöehdot</a>',
    discounted_total: 'Alennettu kokonaissumma',
    upcharge: 'Lisämaksu',
    dont_show_me_this_again: 'Älä näytä tätä enää uudelleen.',
    okay: 'Selvä',
    arrival_date: 'Toimituksen aloituspäivä',
    sso_message: 'Ulkoinen kirjautumistunnus',
    alt_login_title: 'Kirjautumistunnus',
    prebook: 'Ennakkovaraus',
    ats: 'ATS',
    alternate_images: 'Vaihtoehtoiset kuvat',
    choose: 'Valitse',
    new_version_available_modal: 'Uusi versio saatavilla',
    new_version_available_message: 'Sovelluksen uusi versio on saatavilla',
    new_version_reload: 'Lataa uusi versio',
    new_version_snooze: 'Muistuta minua myöhemmin',
    new_version_save_and_reload: 'Tallenna työni ja lataa uudelleen',
    popup_may_be_suppressed_title: 'Maksusivu avattu',
    popup_may_be_suppressed_description: 'Luottokortin maksusivu on avattu. Jos sitä ei näy, tarkista selaimesi osoitepalkki ponnahdusikkunoiden eston varalta ja salli ikkunan avaus.',
    popup_blocked_title: 'Ponnahdusikkuna estetty',
    popup_blocked_description: 'Maksun lähetyksen uusi ikkuna estettiin. Tarkista selaimesi asetukset sekä laajennukset ja yritä uudelleen. Ongelman jatkuessa voit tallentaa tilauksesi ja avata Elasticin toisessa selaimessa. Elastic toimii parhaiten Google Chomen uusimmissa versioissa.',
    pay_by_credit_card: 'Maksa luottokortilla',
    do_you_want_to_pay_by_credit_card: 'Haluatko maksaa luottokortilla?',
    pay_by_credit_card_no: 'Ei',
    pay_by_credit_card_yes: 'Kyllä',
    cancel_order_submission_and_payment: 'Peruuta tilauksen lähetys ja maksaminen',
    product_reference_short: 'VIITE: %{reference}',
    product_reference_long: 'Edellisen vuoden varastoyksikkö: %{reference}',
    variation_code: 'Variaatiokoodi',
    pending_orders: 'Odottavat tilaukset',
    freight_discount_message: 'Tämän toimituksen tuotteet on oikeutettu %{sales_program} toimitusalennukseen',
    partial_freight_discount_message: 'Valitse toimitustapa tämän toimituksen tuotteille, joita ei ole oikeutettu %{sales_program} toimitusalennukseen',
    non_qualifying_items_ship_by_default_method: 'Tuotteet, joilla ei ole %{sales_program} -oikeutusta, toimitetaan oletustavalla',
    export_pdf: 'Vie PDF',
    ots: 'OTS',
    terms_and_conditions: 'Käyttöehdot',
    accept: 'Hyväksy',
    back_to_orders: 'Takaisin tilauksiin',
    back_to_payments: 'Takaisin maksuihin',
    back_to_shipments: 'Takaisin toimituksiin',
    required: 'Pakollinen',
    send_request: 'lähetä pyyntö',
    should_not_empty: 'ei saa olla tyhjä',
    should_be_number: 'kuuluu olla numero',
    incorrect_value: 'virheellinen arvo',
    incorrect_phone: 'puhelinnumeron on oltava kansainvälisessä muodossa +xx',
    board_riders_description: 'Boardriders, Inc. on johtava urheilu- ja lifestyle-yritys, joka suunnittelee, tuottaa ja jakaa brändättyjä vaatteita, kenkiä ja lisätarvikkeita Boardriders-myymälöille kaikkialle maailmassa. Yrityksen vaatteet ja kengät huokuvat rentoa elämäntyyliä nuorekkaille ihmisille, jotka saavat inspiraatiota ulkoilmaurheilusta.',
    region_error_message: 'sinun on valittava alue',
    country_error_message: 'sinun on valittava maa',
    state_error_message: 'sinun on valittava osavaltio/maakunta',
    config_error_message: 'Tämän kaavakkeen avaamisen yhteydessä ilmeni ongelma puuttuvien järjestelmänvalvoja-asetusten vuoksi.',
    success: 'Onnistui',
    error: 'Häiriö',
    invalid_phone_number: 'Virheellinen puhelinnumero',
    invalid_email: 'Virheellinen sähköposti',
    submit: 'Lähetä',
    success_message: 'Tietosi on lähetetty',
    error_message: 'Tietojesi lähettämisen yhteydessä ilmeni häiriö. Yritä uudelleen tai ota yhteyttä asiakastukeen ongelman jatkuessa.',
    no_schema: 'Tämän ominaisuuden asetuksia ei ole määritetty',
    reset: 'Nollaa',
    quick_entry_table_header_shipment_b2c: 'Toimitus',
    enter_quantities_segmented_control_b2c_by_shipment: 'Toimituksen mukaan',
    new_document_b2c_shipment: 'Toimitus',
    boxed_order: 'Pakattu tilaus',
    boxed_order_explanation_title: 'Pakatut tilaukset',
    boxed_order_explanation: 'Useamman kuin yhden laatikon lisääminen toimii lukumäärän kertoimena. Annetut lukumäärät kerrotaan jokaisessa # annetussa laatikossa.',
    boxes: 'Laatikot',
    units_per_box: 'Tuotteita per laatikko',
    regional: 'Alueellinen',
    purchase_order_tooltip: 'Annettu arvo ei kelpaa.',
    tournament_date_is_too_late: 'Päivämäärän on oltava %{date} tai sitä ennen',
    tournament_date_is_too_early: 'Päivämäärän on oltava %{date} tai sen jälkeen',
    page_notes_error: 'Annettu arvo ei kelpaa.',
    order_name_not_valid: 'Annettu arvo ei kelpaa.',
    internet_connection_lost: 'Verkkoyhteys katkesi',
    internet_connection_lost_builder_description: '<p>Sinulla ei ole verkkoyhteyttä. Muodosta uusi yhteys jatkaaksesi.</p><p>Jos tietojen menettäminen huolettaa, napsauta alla olevaa painiketta ladataksesi varmuuskopiotiedoston ja lähetä kyseinen tiedosto osoitteeseen <Link>support@elasticsuite.com</Link> toistaaksesi tilauksesi.</p>',
    internet_connection_lost_description: 'Sinulla ei tällä hetkellä ole verkkoyhteyttä. Muodosta yhteys uudelleen voidaksesi jatkaa.',
    download_backup_data_file: 'Lataa varmuuskopiotiedosto',
    invalid_session: 'Virheellinen käyttökerta',
    invalid_session_builder_description: '<p>Sinulla ei ole verkkoyhteyttä. Muodosta uusi yhteys jatkaaksesi.</p><p>Jos tietojen menettäminen huolettaa, napsauta alla olevaa painiketta ladataksesi varmuuskopiotiedoston ja lähetä kyseinen tiedosto osoitteeseen <Link>support@elasticsuite.com</Link> toistaaksesi tilauksesi.</p>',
    invalid_session_description: 'Sinulla ei tällä hetkellä ole verkkoyhteyttä. Muodosta yhteys uudelleen voidaksesi jatkaa.',
    submit_order_description: 'Tilauksesi lähetetään pian. Lähettämisen jälkeen se lukitaan, jonka jälkeen sitä ei voida enää muokata. Otathan huomioon, että tilauksesi tallennetaan pilvipalveluun.',
    all_sells_final: 'Kaikki tapahtumatilaukset ovat lopullisia, eikä niillä ole palautusoikeutta. Varmista, että olet tarkistanut tilauksen ennen lähettämistä. Kiitoksia tuestasi!',
    invalid_date: 'Virheellinen päivämäärä',
    date_placeholder: 'KK-PP-VVVV',
  }),
);
