define(
  ({
    app_name: 'Elastic',
    none: 'Egyik sem',
    select: 'kiválasztás...',
    cancel: 'Mégse',
    drop_ship_instructions: 'Csak belföldi szállítás. Nincs szállítás APO vagy FPO címekre.',
    product_data_sheet_tab_technology: 'Technológia',
    product_data_sheet_tab_overview: 'Áttekintés',
    product_data_sheet_tab_details: 'Részletek',
    cart_submit_order_dealer: 'Megrendelés',
    available_as_of: 'Elérhető ettől kezdve: <date />',
    cart_submit_order_rep: 'Megrendelés',
    cart_submit_order: 'Rendelés elküldése',
    erp_order_number: 'ERP rendelésszám',
    sap_order_number: 'SAP rendelésszám',
    nda_explanation: 'NDA magyarázat.',
    terms_and_conditions_description: 'Az alábbi jelölőnégyzet bejelölésével jelezd, hogy elolvastad és egyetértesz a <a href="###" target="_blank">Szolgáltatási feltételekkel</a>',
    discounted_total: 'Kedvezményekkel összesen',
    upcharge: 'Felár',
    dont_show_me_this_again: 'Ne mutasd újra.',
    okay: 'Rendben',
    arrival_date: 'Szállítás kezdési dátuma',
    sso_message: 'Külső bejelentkezés',
    alt_login_title: 'Bejelentkezés',
    prebook: 'Előfoglalás',
    ats: 'ATS',
    alternate_images: 'Alternatív képek',
    choose: 'Kiválasztás',
    new_version_available_modal: 'Új verzió elérhető',
    new_version_available_message: 'Az alkalmazás új verziója érhető el',
    new_version_reload: 'Új verzió betöltése',
    new_version_snooze: 'Emlékeztess később',
    new_version_save_and_reload: 'Munka mentése és újra betöltés',
    popup_may_be_suppressed_title: 'Fizetési oldal megnyitva',
    popup_may_be_suppressed_description: 'Egy hitelkártya-fizetési oldal lett megnyitva. Ha nem látod, ellenőrizd a böngésző címsorában a letiltott elugró ablakot, és engedélyezd.',
    popup_blocked_title: 'Felugró letiltva',
    popup_blocked_description: 'Letiltásra került az új ablak a fizetés elküldéséhez. Ellenőrizd a böngésződ beállításait és bővítményeit, majd próbáld újra. Ha a probléma továbbra is fennáll, mentsd el a rendelésed, és nyisd meg az Elastic-ot egy másik böngészőben. Az Elastic leginkább a Google Chrome verzióiban támogatott.',
    pay_by_credit_card: 'Fizetés hitelkártyával',
    do_you_want_to_pay_by_credit_card: 'Hitelkártyával szeretnél fizetni?',
    pay_by_credit_card_no: 'Nem',
    pay_by_credit_card_yes: 'Igen',
    cancel_order_submission_and_payment: 'Rendelés elküldésének és kifizetésének visszavonása',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'Előző évi SKU: %{reference}',
    variation_code: 'Variációs kód',
    pending_orders: 'Függőben lévő rendelések',
    freight_discount_message: 'Az ebben a szállítmányban lévő tételek jogosultak a %{sales_program} szállítási kedvezményre',
    partial_freight_discount_message: 'Válassz szállítási módot az ebben a szállítmányban lévő, a %{sales_program} szállítási kedvezményre nem jogosult tételekhez',
    non_qualifying_items_ship_by_default_method: 'Azokat a termékeket, amelyek nem jogosultak a %{sales_program} programra, az alapértelmezett módszerrel szállítjuk',
    export_pdf: 'PDF exportálása',
    ots: 'OTS',
    terms_and_conditions: 'Feltételek',
    accept: 'Elfogadás',
    back_to_orders: 'Vissza a rendelésekhez',
    back_to_payments: 'Vissza a fizetésekhez',
    back_to_shipments: 'Vissza a szállításokhoz',
    required: 'Kötelező',
    send_request: 'kérés elküldése',
    should_not_empty: 'nem lehet üres',
    should_be_number: 'számnak kell lennie',
    incorrect_value: 'helytelen érték',
    incorrect_phone: 'a telefonszámnak a nemzetközi formátummal egyezőnek kell lennie +xx',
    board_riders_description: 'A Boardriders, Inc. egy vezető akciósport- és életstílus-vállalat, amely márkás ruházatot, lábbelit és kiegészítőket tervez, gyárt és forgalmaz a szörfösök számára világszerte. A vállalat ruházati és lábbeli márkái a szabadidős akciósportok iránti szenvedély által inspirált, fiatalos gondolkodású emberek laza életstílusát képviselik.',
    region_error_message: 'ki kell választani a régiót',
    country_error_message: 'ki kell választani az országot',
    state_error_message: 'ki kell választani az államot/tartományt',
    config_error_message: 'Hiba történt az űrlap megnyitásakor a hiányzó adminisztrációs beállítások miatt.',
    success: 'Siker',
    error: 'Hiba',
    invalid_phone_number: 'Érvénytelen telefonszám',
    invalid_email: 'Érvénytelen e-mail',
    submit: 'Elküldés',
    success_message: 'Sikeresen elküldted az adataidat',
    error_message: 'Hiba történt az adataid elküldése közben. Próbáld meg újra, vagy lépj kapcsolatba a támogatással, ha a probléma továbbra is fennáll.',
    no_schema: 'Ez a jellemző még nincs konfigurálva',
    reset: 'Visszaállítás',
    quick_entry_table_header_shipment_b2c: 'Szállítás',
    enter_quantities_segmented_control_b2c_by_shipment: 'Szállítás szerint',
    new_document_b2c_shipment: 'Szállítás',
    boxed_order: 'Dobozos rendelés',
    boxed_order_explanation_title: 'Dobozos rendelések',
    boxed_order_explanation: 'Egynél több doboz hozzáadása egy rendeléshez mennyiségi szorzóként hat. A megadott mennyiségek megduplázódnak a megadott dobozok számával.',
    boxes: 'Dobozok',
    units_per_box: 'Dobozonkénti egységek',
    regional: 'Regionális',
    purchase_order_tooltip: 'A megadott érték érvénytelen.',
    tournament_date_is_too_late: 'A dátumnak %{date} napon vagy előtte kell lennie.',
    tournament_date_is_too_early: 'A dátumnak %{date} napon vagy utána kell lennie',
    page_notes_error: 'A megadott érték érvénytelen.',
    order_name_not_valid: 'A megadott érték érvénytelen.',
    internet_connection_lost: 'Internetkapcsolat elveszett',
    internet_connection_lost_builder_description: '<p>Nincs internetkapcsolat. A folytatáshoz, kérjük, csatlakozz újra. Az elvégeztt munkád a legutóbbi manuális vagy automatikus mentés során mentésre került.</p><p>Ha adatvesztés miatt aggódsz, kérjük, kattints az alábbi gombra egy biztonsági adatfájl letöltéséhez, és továbbítsd a fájlt a <Link>support@elasticsuite.com</Link> címre a megrendelés visszaállításához.</p>',
    internet_connection_lost_description: 'Jelenleg nincs internetkapcsolat. A folytatáshoz, kérjük, csatlakozz újra.',
    download_backup_data_file: 'Biztonsági adatfájl letöltése',
    invalid_session: 'Érvénytelen munkamenet',
    invalid_session_builder_description: '<p>Nincs internetkapcsolatod. A folytatáshoz, kérjük, csatlakozz újra. Munkád a legutóbbi manuális vagy automatikus mentésen lett mentve.</p><p>Ha adatvesztés miatt aggódsz, kérjük, kattints az alábbi gombra egy biztonsági mentési adatfájl letöltéséhez, és továbbítsd a fájlt a <Link>support@elasticsuite.com</Link> címre a megrendelés újbóli beállításához.</p>',
    invalid_session_description: 'Jelenleg nincs internetkapcsolat. A folytatáshoz, kérjük, csatlakozz újra.',
    submit_order_description: 'A rendelésed elküldés előtt áll. Az elküldést követően lezárásra kerül, és többé nem lesz szerkeszthető. Tájékoztatunk, hogy a rendelésed a felhőbe lesz mentve.',
    all_sells_final: 'Minden megrendelés végleges, és nem áll módunkban visszaküldeni. Kérjük, ellenőrizd a megrendelésedet, mielőtt elküldöd. Köszönjük támogatásodat!',
    invalid_date: 'Érvénytelen dátum',
    date_placeholder: 'HH-NN-ÉÉÉÉ',
  }),
);
