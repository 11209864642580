define(
  ({
    continue: '계속',
    cancel: '취소',
    current_customization_will_be_reset: '현재 사용자 정의가 초기화됩니다',
    rogue_warning: '계속 진행하면 현재 환경 설정이 초기화되고 사용자 정의 절차가 다시 시작됩니다. 그래도 계속 진행하시겠습니까?',
    copy: '복사',
    paste: '붙여넣기',
    copyToAll: '모두에 복사',
    add_to_cart: '카트에 추가',
    addToCart: '카트에 추가',
    remove: '제거',
    added: '추가됨',
    dropped: '취소됨',
    units: '단위',
    total: '합계',
    subtotal: '소계',
    moveToShipment: '배송으로 이동',
    chooseOption: '옵션 선택',
    availability_msg: '요청한 일부 수량이 해당 배송일에는 구입이 불가합니다',
    atsWarning: '이 수량은 %{availableOn}까지 확보할 수 없습니다.',
    inventory_warehouse: '재고품 창고',
    notInCatalog: '카트에 항목을 추가할 수 없음',
    notInCatalogExplanation: '죄송하지만, 카탈로그 간 항목 추가는 이용할 수 없습니다.',
    digital_market_notInCatalogExplanation: '카트에는 한 번에 1개 브랜드의 항목만 포함할 수 있습니다',
    removeFromCartSuggestion: '<link>카트를 비우시겠습니까?</link>',
    digital_market_removeFromCartSuggestion: '<link>카트를 비우면</link> 이 항목을 추가할 수 있습니다',
    emptyCart: '카트 비우기',
    empty: '비어 있음',
    emptyConfirmation: '카트를 비우시겠습니까?',
    available_on: '이용 가능',
    personalization: '개인화',
    personalize: '개인화',
    configuration: '환경 설정',
    configure: '사용자 지정',
    placement: '배치',
    content: '콘텐츠',
    color: '색깔',
    product_specification: '제품 사양',
    type: '유형',
    logo: '로고',
    text: '텍스트',
    text_and_logo: '텍스트, 로고',
    other: '기타',
    value_might_be_stale: '카트의 변경 사항 때문에, 이 값이 유효하지 않습니다. 주문을 저장해서 다시 계산하세요.',
    more_details: '추가 세부 정보',
    availability: '구입 가능 여부',
    no_scheduled_availability: '일정 상의 구입 가능 여부',
    not_available_until: '<date />까지 사용 불가',
    available_as_of: '<date /> 현재 사용 가능',
    units_bundled_singular_label: '%{units} 유닛 번들',
    units_bundled_label: '%{units} 유닛 번들',
    add_bundle_to_cart: '카트에 번들 추가',
    bundles: '번들',
    bundled: '번들 단위',
    product: '제품',
    sizes_and_quantities: '크기 / 수량',
    units_singular_label: '%{units} 유닛',
    units_label: '%{units} 유닛',
    current_availability: '현재 구입 가능 여부',
    future_availability: '향후 구입 가능 여부',
    size: '사이즈',
    sku: 'SKU',
    availability_date: '구입 가능 날짜',
    quantity: '수량',
    availability_subject_to_change: '구입 가능 여부는 변경될 수 있습니다',
    future_availability_subject_to_change: '*구입 가능 여부는 변경될 수 있으며 계약 재고가 포함됩니다.',
    contract_inventory_availability: '계약 재고 구입 가능 여부**',
    contract_inventory_footer: '**계약 재고 구입 가능 여부는 주문 제출 후에 업데이트 됩니다.',
    yes: '예',
    no: '아니요',
    copy_to_all_confirmation_message: '이 크기 범위를 아래 모든 스타일에 복사하시겠습니까?',
    no_configurator_data: '환경 설정기 데이터를 사용할 수 없습니다',
    oops: '이런!',
    something_went_wrong: '문제가 생겼습니다!',
    error_message: '걱정마세요. 지원팀에 이 오류에 대해 알렸습니다!',
    customize_item: '항목 사용자 지정',
    per_item_upgrades: '항목 당 업그레이드',
    number_of_items: '항목 수',
    upgrades_total: '업그레이드 총계',
    total_price_message: '* 총 가격은 카트에서 계산됩니다.',
    customizer_summary: '환경 설정 요약',
    save: '저장',
    field_required: '%{field}은(는) 필수입니다',
    review_configuration: '환경 설정 검토',
    review_configurations: '환경 설정 검토',
    dismiss: '무시',
    review: '검토',
    cyrp_changed: '설정 제품 구성 요소의 사용 가능 여부가 변경되었을 수 있습니다. 제품 설정을 확인해보세요.',
    cyrp_review_warning: '설정 제품 구성 요소의 사용 가능 여부가 변경되었을 수 있습니다. 계속하려면 제품 설정을 확인해보세요.',
    quantity_not_available: '이 구성 요소는 현재 요청한 수량만큼 구입이 불가합니다. 재고가 있는 대체 항목을 선택하세요.',
    customized: '사용자 지정됨',
    personalized: '개인 설정됨',
    customization: '사용자 지정',
    preview: '미리보기',
    preview_design: '디자인 미리보기',
    copy_preview_url: '미리보기 URL 복사',
    edit: '편집',
  }),
);
