define(
  ({
    continue: 'Continuar',
    cancel: 'Cancelar',
    current_customization_will_be_reset: 'La personalización actual se reiniciará',
    rogue_warning: 'Si continuas, tu configuración actual se reiniciará y volverás a empezar el proceso de personalización. ¿Seguro que quieres continuar?',
    copy: 'Copiar',
    paste: 'Pegar',
    copyToAll: 'Copiar a todo',
    add_to_cart: 'Añadir al carrito',
    addToCart: 'Agregar al carro',
    remove: 'Eliminar',
    added: 'Añadido',
    dropped: 'Entregado',
    units: 'Unidades',
    total: 'Total',
    subtotal: 'Subtotal',
    moveToShipment: 'Mover al envío',
    chooseOption: 'Selecciona una opción',
    availability_msg: 'Algunas cantidades solicitadas no están disponibles en tu fecha de envío',
    atsWarning: 'Esta cantidad no estará disponible hasta el %{availableOn}.',
    inventory_warehouse: 'Almacén de inventario',
    notInCatalog: 'No es posible añadir el artículo al carrito',
    notInCatalogExplanation: 'Lo sentimos, pero no está disponible añadir artículos entre catálogos.',
    digital_market_notInCatalogExplanation: 'Tu carrito solo puede contener objetos de una marca al mismo tiempo',
    removeFromCartSuggestion: '¿Te gustaría <link>vaciar tu carrito?</link>',
    digital_market_removeFromCartSuggestion: 'Puedes <link>vaciar el carrito</link> para añadir este objeto',
    emptyCart: 'Vaciar carrito',
    empty: 'Vacío',
    emptyConfirmation: '¿Seguro que quieres vaciar tu carrito?',
    available_on: 'Disponible en',
    personalization: 'Personalización',
    personalize: 'Personalizar',
    configuration: 'Configuración',
    configure: 'Personalizar',
    placement: 'Emplazamiento',
    content: 'Contenido',
    color: 'Color',
    product_specification: 'Especificación de producto',
    type: 'Tipo',
    logo: 'Logo',
    text: 'Texto',
    text_and_logo: 'Texto, logo',
    other: 'Otro',
    value_might_be_stale: 'Debido a los cambios en tu pedido, este valor puede estar fuera de fecha. Guarda tu pedido para recalcular.',
    more_details: 'Más detalles',
    availability: 'Disponibilidad',
    no_scheduled_availability: 'No hay disponibilidad programada',
    not_available_until: 'No disponible hasta <date />',
    available_as_of: 'Disponible el <date />',
    units_bundled_singular_label: '%{units} unidad en lote',
    units_bundled_label: '%{units} unidades en lote',
    add_bundle_to_cart: 'Añadir lote al carrito',
    bundles: 'Lotes',
    bundled: 'En lote',
    product: 'Producto',
    sizes_and_quantities: 'Tamaño / Cantidad',
    units_singular_label: '%{units} unidad',
    units_label: '%{units} unidades',
    current_availability: 'Disponibilidad actual',
    future_availability: 'Disponibilidad futura',
    size: 'Tamaño',
    sku: 'SKU',
    availability_date: 'Fecha de disponibilidad',
    quantity: 'Cant.',
    availability_subject_to_change: 'La disponibilidad está sujeta a cambios',
    future_availability_subject_to_change: '* La disponibilidad está sujeta a cambios e incluye inventario contractual.',
    contract_inventory_availability: 'Disponibilidad de inventario contractual**',
    contract_inventory_footer: '** La disponibilidad de inventario contractual actualizará la siguiente orden de entrega.',
    yes: 'Sí',
    no: 'No',
    copy_to_all_confirmation_message: '¿Seguro que quieres copiar este rango de tamaños a todos los estilos siguientes?',
    no_configurator_data: 'No hay datos del configurador disponibles',
    oops: '¡Ups!',
    something_went_wrong: '¡Algo ha ido mal!',
    error_message: '¡No te preocupes! Nos hemos puesto en contacto con el servicio de asistencia en tu nombre para advertirles de este error.',
    customize_item: 'Personalizar artículo',
    per_item_upgrades: 'Mejoras por artículo',
    number_of_items: 'Número de artículos',
    upgrades_total: 'Total de mejoras',
    total_price_message: '* El precio total se calculará en el carro.',
    customizer_summary: 'Resumen de la configuración',
    save: 'Guardar',
    field_required: '%{field} es obligatorio',
    review_configuration: 'Revisar configuración',
    review_configurations: 'Revisar configuraciones',
    dismiss: 'Descartar',
    review: 'Revisar',
    cyrp_changed: 'La disponibilidad de los componentes de tu(s) producto(s) configurado(s) podría haber cambiado. Revisa la(s) configuración(es) de tu(s) producto(s).',
    cyrp_review_warning: 'La disponibilidad de los componentes en tu(s) producto(s) configurado(s) podría haber cambiado. Revisa la(s) configuración(es) de tu(s) producto(s) para continuar.',
    quantity_not_available: 'Este componente no está disponible actualmente en la cantidad solicitada. Selecciona un artículo alternativo que esté en stock.',
    customized: 'Personalizado',
    personalized: 'A medida',
    customization: 'Personalización',
    preview: 'Vista previa',
    preview_design: 'Previsualizar diseño',
    copy_preview_url: 'Copiar URL de vista previa',
    edit: 'Editar',
  }),
);
