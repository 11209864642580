define(
  ({
    continue: 'Doorgaan',
    cancel: 'Annuleren',
    current_customization_will_be_reset: 'Huidige aanpassingen worden gereset',
    rogue_warning: 'Als je doorgaat, worden je huidige aanpassingen gereset en moet het het aanpassingsproces opnieuw doorlopen. Doorgaan?',
    copy: 'Kopiëren',
    paste: 'Plakken',
    copyToAll: 'Naar alles kopiëren',
    add_to_cart: 'Aan winkelwagen toevoegen',
    addToCart: 'Aan winkelwagen toevoegen',
    remove: 'Verwijderen',
    added: 'Toegevoegd',
    dropped: 'Vervallen',
    units: 'Eenheden',
    total: 'Totaal',
    subtotal: 'Subtotaal',
    moveToShipment: 'Doorgaan naar verzending',
    chooseOption: 'Kiezen als optie',
    availability_msg: 'Sommige aangevraagde hoeveelheden zijn niet beschikbaar op de verzenddatum',
    atsWarning: 'Deze hoeveelheid is niet beschikbaar op %{availableOn}.',
    inventory_warehouse: 'Inventaris magazijn',
    notInCatalog: 'Kan artikel niet aan winkelwagen toevoegen',
    notInCatalogExplanation: 'Sorry, maar u kunt geen artikelen uit verschillende catalogus toevoegen.',
    digital_market_notInCatalogExplanation: 'Je winkelwagen kan alleen artikelen van hetzelfde merk bevatten',
    removeFromCartSuggestion: 'Wilt u uw <link>winkelwagen leegmaken</link>?',
    digital_market_removeFromCartSuggestion: 'Je kunt <link>je winkelwagen leeg maken</link> om dit artikel toe te voegen',
    emptyCart: 'Winkelwagen leegmaken',
    empty: 'Leegmaken',
    emptyConfirmation: 'Weet u zeker dat u uw winkelwagen wilt leegmaken?',
    available_on: 'Beschikbaar op',
    personalization: 'Personalisering',
    personalize: 'Personaliseren',
    configuration: 'Configuratie',
    configure: 'Aanpassen',
    placement: 'Plaatsing',
    content: 'Inhoud',
    color: 'Kleur',
    product_specification: 'Productgegevens',
    type: 'Type',
    logo: 'Logo',
    text: 'Tekst',
    text_and_logo: 'Tekst, Logo',
    other: 'Overig',
    value_might_be_stale: 'Vanwege wijzigingen aan uw winkelwagen, kan deze waarde achterhaald zijn. Sla uw bestelling op om opnieuw te berekenen.',
    more_details: 'Meer details',
    availability: 'Beschikbaarheid',
    no_scheduled_availability: 'Geen geplande beschikbaarheid',
    not_available_until: 'Niet beschikbaar tot <date />',
    available_as_of: 'Beschikbaar vanaf <date />',
    units_bundled_singular_label: '%{units} unit gebundeld',
    units_bundled_label: '%{units} units gebundeld',
    add_bundle_to_cart: 'Bundel aan winkelwagen toevoegen',
    bundles: 'Bundels',
    bundled: 'Gebundeld',
    product: 'Product',
    sizes_and_quantities: 'Maat/Hoeveelheid',
    units_singular_label: '%{units} unit',
    units_label: '%{units} units',
    current_availability: 'Huidige beschikbaarheid',
    future_availability: 'Toekomstige beschikbaarheid',
    size: 'Grootte',
    sku: 'SKU',
    availability_date: 'Datum beschikbaar',
    quantity: 'Hoev.',
    availability_subject_to_change: 'Beschikbaarheid is onderhevig aan wijzigingen',
    future_availability_subject_to_change: '*Beschikbaarheid is onderhevig aan wijzigingen en is inclusief contractuele inventaris.',
    contract_inventory_availability: 'Beschikbaarheid contractuele inventaris**',
    contract_inventory_footer: '**De beschikbaarheid van contractuele inventaris wordt na het indienen van een order bijgewerkt.',
    yes: 'Ja',
    no: 'Nee',
    copy_to_all_confirmation_message: 'Weet je zeker dat je dit maatbereik op alle onderstaande stijlen wilt toepassen?',
    no_configurator_data: 'Geen configuratiegegevens beschikbaar',
    oops: 'Oeps!',
    something_went_wrong: 'Er is iets verkeerd gegaan!',
    error_message: 'Geen zorgen! We hebben de technische ondersteuning op de hoogte gebracht van deze fout!',
    customize_item: 'Item aanpassen',
    per_item_upgrades: 'Upgrades per artikel',
    number_of_items: 'Aantal artikelen',
    upgrades_total: 'Totaal aantal upgrades',
    total_price_message: '* Eindbedrag wordt in je winkelwagen berekend.',
    customizer_summary: 'Samenvatting van configuratie',
    save: 'Opslaan',
    field_required: '%{field} is vereist',
    review_configuration: 'Configuratie controleren',
    review_configurations: 'Configuraties controleren',
    dismiss: 'Afwijzen',
    review: 'Controleren',
    cyrp_changed: 'De beschikbaarheid van componenten in je geconfigureerde product(en) is mogelijk gewijzigd. Controleer je productconfiguratie(s).',
    cyrp_review_warning: 'De beschikbaarheid van componenten in je geconfigureerde product(en) is mogelijk gewijzigd. Controleer je productconfiguratie(s) om door te gaan.',
    quantity_not_available: 'Deze component is momenteel niet in de gewenste hoeveelheid beschikbaar. Selecteer een alternatief artikel dat op voorraad is.',
    customized: 'Aangepast',
    personalized: 'Gepersonaliseerd',
    customization: 'Aanpassing',
    preview: 'Voorbeeld',
    preview_design: 'Ontwerpvoorbeeld',
    copy_preview_url: 'Voorbeeld-URL kopiëren',
    edit: 'Bewerken',
  }),
);
