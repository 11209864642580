define(
({
  "no_data_message": "기록 없음",
  "order_number": "주문 #",
  "elastic_order_number": "유동적 주문 #",
  "line_number": "행 #",
  "customer_name": "고객 이름",
  "customer_number": "고객 #",
  "location_number": "위치 #",
  "location_name": "위치명",
  "product": "제품 #",
  "style_name": "제품명",
  "code": "색깔 코드",
  "color": "색깔명",
  "status": "라인 상태",
  "states": "주문 상태",
  "tracking": "추적 #",
  "parcel_number": "소포 #",
  "year": "해",
  "season": "계절",
  "order_date": "생성 날짜",
  "cancel_date": "날짜별 취소",
  "invoice_date": "청구일",
  "invoice": "청구 #",
  "invoice_url": "청구 URL",
  "download": "다운로드",
  "view": "보기",
  "ship_date": "배송 요청 날짜",
  "expected_ship_date": "예상 배송일",
  "terms_name": "용어 이름",
  "po_number": "PO #",
  "size": "크기",
  "alt_name": "사이즈 길이",
  "upc": "UPC",
  "units": "수량",
  "unit_singular": "유닛",
  "unit_plural": "유닛",
  "amount": "금액",
  "net_price": "순",
  "unit_price": "개별 가격",
  "orders": "주문",
  "totals": "합계",
  "total": "합계",
  "details": "내용",
  "open": "열기",
  "shipped": "배송됨",
  "cancelled": "취소됨",
  "canceled": "취소됨",
  "released": "발표됨",
  "invoiced": "청구됨",
  "picked": "선택됨",
  "region": "지역",
  "order_type": "주문 유형",
  "backorder_date": "이월 주문 날짜",
  "backorder": "이월 주문",
  "payment_terms": "결제 약관",
  "ship_via": "다음을 통해 배송",
  "line_discount": "계열 할인",
  "rma_number": "RMA #",
  "cash_discount": "현금 할인",
  "hold_for_confirmation": "확인 보류",
  "credit_hold": "크레딧 보류",
  "order_notes": "주문 메모",
  "product_series": "제품 시리즈",
  "product_category": "제품 카테고리",
  "product_sub_category": "제품 하위 카테고리",
  "quantity_confirmed": "확인됨",
  "quantity_backordered": "이월 주문됨",
  "quantity_cancelled": "취소됨s",
  "quantity_shipped": "배송됨",
  "ivendix_order_id": "iVendix ID",
  "catalog": "카탈로그",
  "source": "소스",
  "division": "부서",
  "actual_ship_date": "실제 배송일",
  "rep_name": "대표자명",
  "tracking_carrier": "항공사 코드",
  "gross_amount": "총액",
  "discount_percent": "할인 %",
  "internal_code": "내부 코드",
  "requested_date": "요청일",
  "net_amount_total": "총 판매가",
  "freight_amount": "운임가",
  "client_order_status": "주문 상태",
  "ship_address": "배송 주소",
  "ship_city": "배송 도시",
  "ship_state": "배송 상태",
  "warehouse_name": "창고 이름",
  "client_carrier_code": "고객 항공사 코드",
  "discount_1": "할인 1",
  "discount_2": "할인 2",
  "discount_3": "할인 3",
  "brand": "브랜드",
  "quantity_open": "수량 열기",
  "amount_open": "금액 열기",
  "quantity_invoiced": "청구된 수량",
  "amount_invoiced": "청구된 금액",
  "promo_code": "홍보 코드",
  "size_code": "크기 코드",
  "due_date": "만기일",
  "quantity_ordered": "주문된 수량",
  "quantity_allocated": "할당된 수량",
  "available_date": "구입 가능일",
  "bank_information": "은행 정보",
  "shipment_number": "배송 #",
  "quantity_shipped_sku": "SKU 배송 완료",
  "name": "이름",
  "customs_reference": "세관 참조",
  "currency_code": "통화 코드",
  "id_model_partenaire_1": "파트너 모델 ID",
  "id_model_partenaire_2": "파트너 모델 ID 2",
  "expected_ship_date_range": "예상 배송일 범위"
})
);
