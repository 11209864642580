define(
  ({
    app_name: 'Elastic',
    none: 'Нет',
    select: 'выбрать...',
    cancel: 'Отмена',
    drop_ship_instructions: 'Доставка только по стране. Доставка армейской и флотской почтовыми службами недоступна.',
    product_data_sheet_tab_technology: 'Технология',
    product_data_sheet_tab_overview: 'Обзор',
    product_data_sheet_tab_details: 'Подробные сведения',
    cart_submit_order_dealer: 'Разместить заказ',
    cart_submit_order_rep: 'Разместить заказ',
    cart_submit_order: 'Отправить заказ',
    erp_order_number: 'Номер заказа ERP',
    sap_order_number: 'Номер заказа SAP',
    nda_explanation: 'Пояснение по Соглашению о неразглашении.',
    available_as_of: 'Доступно на дату <date />',
    terms_and_conditions_description: 'Поставьте флажок ниже, чтобы подтвердить, что вы прочитали и принимаете  <a href="###" target="_blank">Условия обслуживания</a>',
    discounted_total: 'Общая сумма со скидкой',
    upcharge: 'Наценка',
    dont_show_me_this_again: 'Больше не показывать.',
    okay: 'Хорошо',
    arrival_date: 'Дата начала отправки',
    sso_message: 'Внешний вход',
    alt_login_title: 'Вход',
    prebook: 'Предварительная бронь',
    ats: 'Доступно для продажи',
    alternate_images: 'Альтернативные изображения',
    choose: 'Выбрать',
    new_version_available_modal: 'Доступна новая версия',
    new_version_available_message: 'Доступна новая версия приложения',
    new_version_reload: 'Загрузить новую версию',
    new_version_snooze: 'Напомнить позже',
    new_version_save_and_reload: 'Сохранить мою работу и перезагрузить',
    popup_may_be_suppressed_title: 'Открыта страница оплаты',
    popup_may_be_suppressed_description: 'Открыта страница оплаты банковской картой. Если вы не видите ее, проверьте, нет ли заблокированного всплывающего окна в адресной строке браузера, и разрешите его отображение.',
    popup_blocked_title: 'Всплывающее окно заблокировано',
    popup_blocked_description: 'Новое окно для отправки платежа было заблокировано. Проверьте настройки и расширения браузера и повторите попытку. Если проблема не исчезнет, попробуйте сохранить заказ и открыть Elastic в другом браузере. Лучше всего Elastic поддерживается в последних версиях Google Chrome.',
    pay_by_credit_card: 'Оплатить банковской картой',
    do_you_want_to_pay_by_credit_card: 'Хотите оплатить банковской картой?',
    pay_by_credit_card_no: 'Нет',
    pay_by_credit_card_yes: 'Да',
    cancel_order_submission_and_payment: 'Отменить отправку заказа и оплату',
    product_reference_short: 'ССЫЛ.: %{reference}',
    product_reference_long: 'SKU за предшествующий год: %{reference}',
    variation_code: 'Код варианта',
    pending_orders: 'Ожидающие заказы',
    freight_discount_message: 'Для товаров в этом отправлении доступна скидка на доставку %{sales_program}',
    partial_freight_discount_message: 'Выберите способ доставки для товаров в этом отправлении, для которых недоступна скидка на доставку %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Товары, для которых недоступна скидка %{sales_program}, будут доставлены стандартным способом',
    export_pdf: 'Экспорт в PDF',
    ots: 'Открыто для продажи',
    terms_and_conditions: 'Условия использования',
    accept: 'Принять',
    back_to_orders: 'Вернуться к заказам',
    back_to_payments: 'Вернуться к оплатам',
    back_to_shipments: 'Вернуться к доставкам',
    required: 'Обязательно',
    send_request: 'отправить запрос',
    should_not_empty: 'нельзя оставлять пустым',
    should_be_number: 'необходимо ввести число',
    incorrect_value: 'неверное значение',
    incorrect_phone: 'Номер телефона должен соответствовать международному формату +xx',
    board_riders_description: 'Boardriders, Inc. — ведущий мировой дизайнер, производитель и дистрибьютер брендовой одежды, обуви и аксессуаров Boardriders для занятий активными видами спорта и отдыха. Одежда и обувь наших брендов — это повседневный стиль для тех, кто ведет активный образ жизни и любит спорт.',
    region_error_message: '— требуется выбрать регион',
    country_error_message: '— требуется выбрать страну',
    state_error_message: '— требуется выбрать штат/провинцию',
    config_error_message: 'При открытии этой формы произошла ошибка, так как вы не являетесь администратором.',
    success: 'Готово',
    error: 'Ошибка',
    invalid_phone_number: 'Неверный номер телефона',
    invalid_email: 'Неверный адрес эл. почты',
    submit: 'Отправить',
    success_message: 'Ваша информация отправлена',
    error_message: 'Возникла ошибка при отправке вашей информации. Повторите попытку и обратитесь в службу поддержки, если проблема не исчезнет.',
    no_schema: 'Данная функция не настроена',
    reset: 'Сброс',
    quick_entry_table_header_shipment_b2c: 'Доставка',
    enter_quantities_segmented_control_b2c_by_shipment: 'По доставке',
    new_document_b2c_shipment: 'Доставка',
    boxed_order: 'Упакованный заказ',
    boxed_order_explanation_title: 'Упакованные заказы',
    boxed_order_explanation: 'При добавлении в заказ нескольких коробок количество товара в одной коробке умножается на количество коробок.',
    boxes: 'Коробки',
    units_per_box: 'Товаров в коробке',
    regional: 'Региональный',
    purchase_order_tooltip: 'Введено недопустимое значение.',
    tournament_date_is_too_late: 'Введите дату не позднее %{date}',
    tournament_date_is_too_early: 'Укажите дату не ранее %{date}',
    page_notes_error: 'Введено недопустимое значение.',
    order_name_not_valid: 'Введено недопустимое значение.',
    internet_connection_lost: 'Отсутствует подключение Интернету',
    internet_connection_lost_builder_description: '<p>Вы не подключены к Интернету. Чтобы продолжить, подключитесь к сети.</p><p>Если вы боитесь потерять данные, нажмите на кнопку ниже, чтобы загрузить файл с резервными данными, а затем отправьте его на адрес <Link>support@elasticsuite.com</Link>, чтобы восстановить заказ.</p>',
    internet_connection_lost_description: 'Сейчас вы не подключены к Интернету. Чтобы продолжить, подключитесь к сети.',
    download_backup_data_file: 'Загрузить файл с резервными данными',
    invalid_session: 'Недействительный сеанс',
    invalid_session_builder_description: '<p>Вы не подключены к Интернету. Чтобы продолжить, подключитесь к сети.</p><p>Если вы боитесь потерять данные, нажмите на кнопку ниже, чтобы загрузить файл с резервными данными, а затем отправьте его на адрес <Link>support@elasticsuite.com</Link>, чтобы восстановить заказ.</p>',
    invalid_session_description: 'Сейчас вы не подключены к Интернету. Чтобы продолжить, подключитесь к сети.',
    submit_order_description: 'Ваш заказ сейчас будет отправлен. После этого он будет заблокирован и станет недоступен для изменения. Обратите внимание, что заказ будет сохранен в облаке.',
    all_sells_final: 'Все заказы в рамках событий считаются окончательными и не подлежат возврату. Не забудьте проверить заказ перед отправкой. Благодарим за поддержку!',
    invalid_date: 'Неверная дата',
    date_placeholder: 'ММ-ДД-ГГГГ',
  }),
);
