define(
  ({
    app_name: 'Elastic',
    none: 'Ingen',
    select: 'välj...',
    cancel: 'Avbryt',
    drop_ship_instructions: 'Endast inrikesleverans. Ingen leverans till fält- eller marinpostkontor.',
    product_data_sheet_tab_technology: 'Teknik',
    product_data_sheet_tab_overview: 'Översikt',
    product_data_sheet_tab_details: 'Detaljuppgifter',
    cart_submit_order_dealer: 'Lägg beställning',
    cart_submit_order_rep: 'Lägg beställning',
    available_as_of: 'I lager fr o m <date />',
    cart_submit_order: 'Skicka beställning',
    erp_order_number: 'ERP-beställningsnummer',
    sap_order_number: 'SAP-beställningsnummer',
    nda_explanation: 'Sekretessförklaring',
    terms_and_conditions_description: 'Markera nedan att du har läst och godkänner <a href="###" target="_blank">användarvillkoren och bestämmelserna</a>',
    discounted_total: 'Total rabatt',
    upcharge: 'Påslag',
    dont_show_me_this_again: 'Visa inte detta igen.',
    okay: 'OK',
    arrival_date: 'Leveransens startdatum',
    sso_message: 'Extern inloggning',
    alt_login_title: 'Inloggning',
    prebook: 'Förboka',
    ats: 'ATS',
    alternate_images: 'Alternativa bilder',
    choose: 'Välj',
    new_version_available_modal: 'Det finns en ny version',
    new_version_available_message: 'Det finns en ny version av applikationen',
    new_version_reload: 'Ladda ny version',
    new_version_snooze: 'Påminn mig senare',
    new_version_save_and_reload: 'Spara mitt arbete och ladda om',
    popup_may_be_suppressed_title: 'Betalningssidan är öppen',
    popup_may_be_suppressed_description: 'En sida för betalning med kreditkort har öppnats. Om den inte visas, titta i din webbläsare och se om ett extrafönster har blockerats. Tillåt i så fall det.',
    popup_blocked_title: 'Extrafönster har blockerats',
    popup_blocked_description: 'Det nya fönstret som används för att genomföra betalningar blockerades. Kontrollera webbläsarens inställningar och tillägg och försök därefter igen. Om problemet kvarstår kan du spara din beställning och öppna Elastic i en annan webbläsare. Elastic fungerar bäst med de senaste versionerna av Google Chrome.',
    pay_by_credit_card: 'Betala med kreditkort',
    do_you_want_to_pay_by_credit_card: 'Vill du betala med kreditkort?',
    pay_by_credit_card_no: 'Nej',
    pay_by_credit_card_yes: 'Ja',
    cancel_order_submission_and_payment: 'Avbryt beställning och betalning',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'Tidigare SKU (lagerenhet): %{reference}',
    variation_code: 'Variationskod',
    pending_orders: 'Pågående beställningar',
    freight_discount_message: 'Produkter i denna leverans omfattas av leveransrabatten i %{sales_program}',
    partial_freight_discount_message: 'Välj en leveransmetod för produkter i denna leverans som inte omfattas av leveransrabatten i %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Produkter som inte omfattas av %{sales_program} levereras via standardmetoden',
    export_pdf: 'Exportera PDF',
    ots: 'Redo för försäljning',
    terms_and_conditions: 'Villkor och bestämmelser',
    accept: 'Godkänn',
    back_to_orders: 'Tillbaka till Beställningar',
    back_to_payments: 'Tillbaka till Betalningar',
    back_to_shipments: 'Tillbaka till Leveranser',
    required: 'Måste anges',
    send_request: 'skicka förfrågan',
    should_not_empty: 'ska inte vara tom',
    should_be_number: 'ska vara ett nummer',
    incorrect_value: 'felaktigt värde',
    incorrect_phone: 'telefonnumret måste skrivas på internationellt format +xx',
    board_riders_description: 'Boardriders, Inc. är ett ledande företag inom actionsport och aktiv livsstil som designar, tillverkar och distribuerar märkeskläder, skor och tillbehör för surfare i hela världen. Företagets kläd- och skomärken representerar en avslappnad livsstil för ungdomliga människor som inspireras av utomhusidrott.',
    region_error_message: 'måste välja region',
    country_error_message: 'måste välja land',
    state_error_message: 'måste välja delstat/provins',
    config_error_message: 'Formuläret kunde inte öppnas på grund av saknade administrativa inställningar.',
    success: 'Klart',
    error: 'Fel',
    invalid_phone_number: 'Ogiltigt telefonnummer',
    invalid_email: 'Ogiltig e-postadress',
    submit: 'Skicka',
    success_message: 'Din information har skickats',
    error_message: 'Det gick inte att skicka in din information. Försök igen eller kontakta supporten om problemet kvarstår.',
    no_schema: 'Den här funktionen har inte konfigurerats',
    reset: 'Återställ',
    quick_entry_table_header_shipment_b2c: 'Leverans',
    enter_quantities_segmented_control_b2c_by_shipment: 'Efter leverans',
    new_document_b2c_shipment: 'Leverans',
    boxed_order: 'Gruppbeställning',
    boxed_order_explanation_title: 'Gruppbeställningar',
    boxed_order_explanation: 'Om man lägger till mer än en förpackning i en beställning ökar antalet produkter i beställningen. Angivna antal gäller för alla förpackningar.',
    boxes: 'Förpackningar',
    units_per_box: 'Enheter per förpackning',
    regional: 'Regionellt',
    purchase_order_tooltip: 'Angivet värdet är ogiltigt.',
    tournament_date_is_too_late: 'Datumet måste infalla på eller efter %{date}',
    tournament_date_is_too_early: 'Datumet måste infalla på eller efter %{date}',
    page_notes_error: 'Angivet värde är inte giltigt.',
    order_name_not_valid: 'Det angivna värdet är ogiltigt.',
    internet_connection_lost: 'Internetanslutningen avbröts',
    internet_connection_lost_builder_description: '<p>Du är inte ansluten till internet. Återanslut för att fortsätta. Ditt arbete har antingen sparats i den senaste manualen eller sparats automatiskt.</p><p>Om du är orolig för dataförlust kan du klicka på knappen nedan för att ladda ner en säkerhetskopia och skicka filen till <Link>support@elasticsuite.com</Link> för att återskapa din beställning.</p>',
    internet_connection_lost_description: 'Just nu är du inte ansluten till internet. Återanslut för att fortsätta.',
    download_backup_data_file: 'Ladda ner säkerhetskopia',
    invalid_session: 'Ogiltig session',
    invalid_session_builder_description: '<p>Du är inte ansluten till internet. Återupprätta din anslutning för att fortsätta. Ditt arbete har sparats fram till den senaste gången du sparade manuellt eller systemet sparade automatiskt.</p><p>Om du misstänker att du har förlorat information kan du klicka på knappen nedan för att ladda ner en säkerhetskopierad datafil. Vidarebefordra filen till <Link>support@elasticsuite.com</Link> för att återställa din beställning.</p>',
    invalid_session_description: 'Just nu är du inte ansluten till internet. Återanslut för att fortsätta.',
    submit_order_description: 'Din beställning är på väg att skickas. När den har skickats kommer den att vara låst och kan inte längre redigeras. Observera att beställningen sparas i molnet.',
    all_sells_final: 'Evenemangsbeställningar är slutgiltiga och kan inte returneras. Glöm inte att granska din beställning innan du skickar in den. Tack för ditt stöd!',
    invalid_date: 'Ogiltigt datum',
    date_placeholder: 'ÅÅÅÅ-MM-DD',
  }),
);
