define(
  ({
    app_name: 'Elastic',
    none: 'Nenhum',
    select: 'selecionar...',
    cancel: 'Cancelar',
    drop_ship_instructions: 'Apenas envio nacional. Sem envio para APOs ou FPOs.',
    product_data_sheet_tab_technology: 'Tecnologia',
    product_data_sheet_tab_overview: 'Descrição',
    product_data_sheet_tab_details: 'Detalhes',
    cart_submit_order_dealer: 'Encomendar',
    cart_submit_order_rep: 'Encomendar',
    cart_submit_order: 'Submeter encomenda',
    erp_order_number: 'Encomenda ERP N.º',
    sap_order_number: 'Encomenda SAP N.º',
    nda_explanation: 'Explicação NDA.',
    terms_and_conditions_description: 'Marque abaixo para indicar que leu e concordou com os <a href="###" target="_blank">Termos e condições</a>',
    discounted_total: 'Total com desconto',
    upcharge: 'Aumentar',
    available_as_of: 'Disponível a partir de <date />',
    dont_show_me_this_again: 'Não voltar a mostrar.',
    okay: 'OK',
    arrival_date: 'Data de início do envio',
    sso_message: 'Início de sessão externo',
    alt_login_title: 'Iniciar sessão',
    prebook: 'Pre-Encomenda',
    ats: 'ATS',
    alternate_images: 'Alterar imagens',
    choose: 'Escolher',
    new_version_available_modal: 'Nova versão disponível',
    new_version_available_message: 'Está disponível uma nova versão da aplicação',
    new_version_reload: 'Carregar nova versão',
    new_version_snooze: 'Lembrar-me mais tarde',
    new_version_save_and_reload: 'Guardar o meu trabalho e recarregar',
    popup_may_be_suppressed_title: 'Aberta a página de pagamento',
    popup_may_be_suppressed_description: 'Uma página de pagamento com cartão de crédito foi aberta. Se não a vir, verifique a barra de endereços do seu navegador para encontrar um popup bloqueado e permiti-lo.',
    popup_blocked_title: 'Popup bloqueado',
    popup_blocked_description: 'A nova janela para submeter o seu pagamento foi bloqueada. Verifique as definições do seu navegador e das extensões e tente novamente. Se o problema continuar, pode guardar a sua encomenda e abrir a Elastic noutro navegador. A Elastic é melhor suportada nas versões mais recentes do Google Chrome.',
    pay_by_credit_card: 'Pagar com cartão de crédito',
    do_you_want_to_pay_by_credit_card: 'Quer pagar com o cartão de crédito?',
    pay_by_credit_card_no: 'Não',
    pay_by_credit_card_yes: 'Sim',
    cancel_order_submission_and_payment: 'Cancelar a submissão da encomenda e pagamento',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'SKU do ano anterior: %{reference}',
    variation_code: 'Código de variação',
    pending_orders: 'Encomendas pendentes',
    freight_discount_message: 'Os itens nesta encomenda estão elegíveis para o desconto de envio %{sales_program}',
    partial_freight_discount_message: 'Escolhe um método de envio para os itens nesta encomenda que não se qualificam para o desconto de envio %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Os itens que não são elegíveis para o %{sales_program} vão ser enviados pelo método padrão',
    export_pdf: 'Exportar PDF',
    ots: 'OTS',
    terms_and_conditions: 'Termos e condições',
    accept: 'Aceitar',
    back_to_orders: 'Voltar às encomendas',
    back_to_payments: 'Voltar aos pagamentos',
    back_to_shipments: 'Voltar aos envios',
    required: 'Necessário',
    send_request: 'enviar pedido',
    should_not_empty: 'não deve estar vazio',
    should_be_number: 'deve ser um número',
    incorrect_value: 'valor incorreto',
    incorrect_phone: 'o número de telefone deve corresponder ao formato internacional +xx',
    board_riders_description: 'A Boardriders, Inc., é uma empresa líder no desenvolvimento, produção e distribuição de vestuário, calçado e acessórios relacionados com desporto aventura, cujo público-alvo são os praticantes de desportos com prancha em todo o mundo. As marcas marcas de vestuário e calçado da empresa representam um estilo de vida descontraído para pessoas descontraídas que se inspirem em desportos de ação de exterior.',
    region_error_message: 'deve escolher a região',
    country_error_message: 'deve escolher o país',
    state_error_message: 'deve escolher o distrito/concelho',
    config_error_message: 'Ocorreu um erro ao abrir este formulário por faltarem definições de administração.',
    success: 'Sucesso',
    error: 'Erro',
    invalid_phone_number: 'Número de telefone inválido',
    invalid_email: 'E-mail inválido',
    submit: 'Submeter',
    success_message: 'A sua informação foi submetida com sucesso',
    error_message: 'Ocorreu um erro ao submeter a sua informação. Tente novamente ou contacte o apoio se o problema continuar.',
    no_schema: 'Esta funcionalidade ainda não foi configurada',
    reset: 'Repor',
    quick_entry_table_header_shipment_b2c: 'Envio',
    enter_quantities_segmented_control_b2c_by_shipment: 'Por envio',
    new_document_b2c_shipment: 'Envio',
    boxed_order: 'Encomenda embalada',
    boxed_order_explanation_title: 'Encomendas embaladas',
    boxed_order_explanation: 'Adicionar mais do que uma caixa a uma encomenda age como um multiplicador de quantidade. As quantidades introduzidas serão duplicadas pelo número de caixas introduzidas.',
    boxes: 'Caixas',
    units_per_box: 'Unidades por caixa',
    regional: 'Regional',
    purchase_order_tooltip: 'O valor introduzido não é válido.',
    tournament_date_is_too_late: 'A data deve ser em ou antes de %{date}',
    tournament_date_is_too_early: 'A data deve ser em ou depois de %{date}',
    page_notes_error: 'O valor introduzido não é válido.',
    order_name_not_valid: 'O valor introduzido não é válido.',
    internet_connection_lost: 'Perdida a ligação à internet',
    internet_connection_lost_builder_description: '<p>Não está ligado à internet. Por favor, volte a ligar-se para continuar. O seu trabalho foi guardado na última operação manual ou automática de guardar.</p><p>Se está preocupado com a perda de dados, por favor, clique no botão abaixo para transferir uma cópia de segurança e encaminhe o ficheiro para<Link>support@elasticsuite.com</Link> para voltar a fazer a sua encomenda.</p>',
    internet_connection_lost_description: 'De momento não está ligado à internet. Por favor, volte a estabelecer uma ligação para continuar.',
    download_backup_data_file: 'Transferir ficheiro de cópia de segurança',
    invalid_session: 'Sessão inválido',
    invalid_session_builder_description: '<p>Não está ligado à internet. Por favor, volte a ligar-se para continuar. O seu trabalho foi guardado na última operação manual ou automática de guardar.</p><p>Se está preocupado com a perda de dados, por favor, clique no botão abaixo para transferir uma cópia de segurança e encaminhe o ficheiro para<Link>support@elasticsuite.com</Link> para voltar a fazer a sua encomenda.</p>',
    invalid_session_description: 'De momento não está ligado à internet. Por favor, volte a estabelecer uma ligação para continuar.',
    submit_order_description: 'A sua encomenda está prestes a ser submetida. Depois de enviada, ficará bloqueada e deixará de ser editável. Por favor, note que a encomenda será guardada na nuvem.',
    all_sells_final: 'Todas as encomendas do evento são finais e não permitem devolução. Por favor, certifique-se de que confirmou a encomenda antes de a submeter. Obrigado pela sua ajuda!',
    invalid_date: 'Data inválida',
    date_placeholder: 'MM-DD-AAAA',
  }),
);
