define(
({
  "no_data_message": "Kayıt yok",
  "order_number": "Sipariş #",
  "elastic_order_number": "Elastik Sipariş #",
  "line_number": "Hat #",
  "customer_name": "Müşteri Adı",
  "customer_number": "Müşteri #",
  "location_number": "Konum #",
  "location_name": "Konum Adı",
  "product": "Ürün #",
  "style_name": "Ürün Adı",
  "code": "Renk Kodu",
  "color": "Renk Adı",
  "status": "Hat Durumu",
  "states": "Sipariş Durumu",
  "tracking": "Takip #",
  "parcel_number": "Paket #",
  "year": "Yıl",
  "season": "Sezon",
  "order_date": "Oluşturulduğu Tarih",
  "cancel_date": "Tarihe Göre İptal",
  "invoice_date": "Fatura Tarihi",
  "invoice": "Fatura #",
  "invoice_url": "Fatura URL'si",
  "download": "İndir",
  "view": "Görüntüle",
  "ship_date": "Talep Edilen Gönderme Tarihi",
  "expected_ship_date": "Beklenen Gönderme Tarihi",
  "terms_name": "Koşullar Adı",
  "po_number": "PO #",
  "size": "Boyut",
  "alt_name": "Boyut Uzunluğu",
  "upc": "UPC",
  "units": "Mkt",
  "unit_singular": "Birim",
  "unit_plural": "Birim",
  "amount": "Tutar",
  "net_price": "Net",
  "unit_price": "Birim Fiyat",
  "orders": "Siparişler",
  "totals": "Toplamlar",
  "total": "Toplam",
  "details": "Ayrıntılar",
  "open": "Aç",
  "shipped": "Gönderildi",
  "cancelled": "İptal Edildi",
  "canceled": "İptal Edildi",
  "released": "Bırakıldı",
  "invoiced": "Faturalandı",
  "picked": "Alındı",
  "region": "Bölge",
  "order_type": "Sipariş Türü",
  "backorder_date": "Geri Sipariş Tarihi",
  "backorder": "Geri Sipariş",
  "payment_terms": "Ödeme Koşulları",
  "ship_via": "Şununla Gönder:",
  "line_discount": "Hat İndirimi",
  "rma_number": "RMA #",
  "cash_discount": "Nakit İndirimi",
  "hold_for_confirmation": "Doğrulama İçin Tut",
  "credit_hold": "Kredi Askıda",
  "order_notes": "Sipariş Notları",
  "product_series": "Ürün Serileri",
  "product_category": "Ürün Kategorisi",
  "product_sub_category": "Ürün Alt Kategorisi",
  "quantity_confirmed": "Doğrulandı",
  "quantity_backordered": "Geri Sipariş Edildi",
  "quantity_cancelled": "İptal Edildi",
  "quantity_shipped": "Gönderildi",
  "ivendix_order_id": "iVendix Kimliği",
  "catalog": "Katalog",
  "source": "Kaynak",
  "division": "Bölüm",
  "actual_ship_date": "Gerçek Gönderme Tarihi",
  "rep_name": "Temsilci Adı",
  "tracking_carrier": "Taşıyıcı Kodu",
  "gross_amount": "Brüt Tutar",
  "discount_percent": "İndirim %",
  "internal_code": "Dâhili Kod",
  "requested_date": "Talep Edilen Tarih",
  "net_amount_total": "Net Toplam Tutar",
  "freight_amount": "Nakliye Miktarı",
  "client_order_status": "Sipariş Durumu",
  "ship_address": "Gönderilecek Adres",
  "ship_city": "Gönderilecek Şehir",
  "ship_state": "Gönderilecek Eyalet",
  "warehouse_name": "Depo Adı",
  "client_carrier_code": "Müşteri Taşıyıcı Kodu",
  "discount_1": "İndirim 1",
  "discount_2": "İndirim 2",
  "discount_3": "İndirim 3",
  "brand": "Marka",
  "quantity_open": "Açık Adet",
  "amount_open": "Açık Miktar",
  "quantity_invoiced": "Fatura Edilen Adet",
  "amount_invoiced": "Fatura Edilen Miktar",
  "promo_code": "Promosyon Kodu",
  "size_code": "Boyut Kodu",
  "due_date": "Son Tarih",
  "quantity_ordered": "Şipariş Edilen Miktar",
  "quantity_allocated": "Tahsis Edilen Miktar",
  "available_date": "Müsait Tarih",
  "bank_information": "Banka Bilgisi",
  "shipment_number": "Gönderim #",
  "quantity_shipped_sku": "Gönderilen SKU",
  "name": "Ad",
  "customs_reference": "Gümrük Referans Kodu",
  "currency_code": "Para Birimi Kodu",
  "id_model_partenaire_1": "Partner Model Kimliği",
  "id_model_partenaire_2": "Partner Model Kimliği 2",
  "expected_ship_date_range": "Beklenen Gönderme Tarih Aralığı"
})
);
