define(
  ({
    app_name: 'Elastic',
    none: 'Žádné',
    select: 'vybrat…',
    cancel: 'Zrušit',
    drop_ship_instructions: 'Pouze vnitrostátní doprava. Žádná zásilka pro APO či FPO.',
    product_data_sheet_tab_technology: 'Technologie',
    product_data_sheet_tab_overview: 'Přehled',
    product_data_sheet_tab_details: 'Podrobnosti',
    cart_submit_order_dealer: 'Zadat objednávku',
    cart_submit_order_rep: 'Zadat objednávku',
    cart_submit_order: 'Odeslat objednávku',
    erp_order_number: 'Č. objednávky v ERP',
    sap_order_number: 'Č. objednávky v SAP',
    nda_explanation: 'Vysvětlení dohody o mlčenlivosti (NDA).',
    terms_and_conditions_description: 'Zaškrtněte níže, že jste si přečetli a souhlasíte s <a href="###" target="_blank">podmínkami</a>',
    discounted_total: 'Zlevněno celkem',
    upcharge: 'Příplatek',
    dont_show_me_this_again: 'Příště už nezobrazovat.',
    okay: 'OK',
    arrival_date: 'Datum zahájení dodávky',
    sso_message: 'Externí přihlášení',
    alt_login_title: 'Přihlášení',
    prebook: 'Předobjednat',
    ats: 'ATS',
    alternate_images: 'Střídat obrázky',
    choose: 'Vybrat',
    new_version_available_modal: 'K dispozici je nová verze',
    new_version_available_message: 'K dispozici je nová verze aplikace',
    new_version_reload: 'Nahrát novou verzi',
    new_version_snooze: 'Připomenout mi později',
    new_version_save_and_reload: 'Uložit mou práci a znovu načíst',
    popup_may_be_suppressed_title: 'Stránka platby otevřena',
    popup_may_be_suppressed_description: 'Byla otevřena stránka pro platbu kreditní kartou. Pokud ji nevidíte, zkontrolujte, zda není v adresním řádku prohlížeče zablokované vyskakovací okno, a případně jej povolte.',
    popup_blocked_title: 'Vyskakovací okno blokováno',
    popup_blocked_description: 'Nové okno pro odeslání platby bylo zablokováno. Zkontrolujte nastavení a rozšíření prohlížeče a zkuste to znovu. Pokud problém přetrvává, můžete objednávku uložit a otevřít Elastic v jiném prohlížeči. Elastic je nejlépe podporován v posledních verzích prohlížeče Google Chrome.',
    pay_by_credit_card: 'Platit kreditní kartou',
    do_you_want_to_pay_by_credit_card: 'Chcete platit kreditní kartou?',
    pay_by_credit_card_no: 'Ne',
    pay_by_credit_card_yes: 'Ano',
    cancel_order_submission_and_payment: 'Zrušit odeslání objednávky a platbu',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'SKU předchozího roku: %{reference}',
    variation_code: 'Kód varianty',
    pending_orders: 'Nevyřízené objednávky',
    freight_discount_message: 'Na položky v této zásilce se vztahuje sleva na dopravu %{sales_program}',
    partial_freight_discount_message: 'Zvolte způsob dopravy pro položky v této zásilce, na které se nevztahuje sleva na dopravu %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Položky, které nesplnily podmínky %{sales_program}, budou odeslány výchozím způsobem',
    export_pdf: 'Exportovat PDF',
    available_as_of: 'K dispozici od <date />',
    ots: 'OTS',
    terms_and_conditions: 'Náležitosti a podmínky',
    accept: 'Přijmout',
    back_to_orders: 'Zpět k objednávkám',
    back_to_payments: 'Zpět k platbám',
    back_to_shipments: 'Zpět k zásilkám',
    required: 'Povinné',
    send_request: 'odeslat požadavek',
    should_not_empty: 'nemělo by být prázdné',
    should_be_number: 'mělo by být číslo',
    incorrect_value: 'nesprávná hodnota',
    incorrect_phone: 'telefonní číslo by mělo odpovídat mezinárodnímu formátu +xx',
    board_riders_description: 'Boardriders, Inc., je přední společností v oblasti akčních sportů a životního stylu, která navrhuje, vyrábí a distribuuje značkové oblečení, obuv a doplňky pro jezdce na prknech po celém světě. Značky oblečení a obuvi společnosti představují neformální životní styl pro mladě smýšlející lidi, kteří jsou inspirováni vášní pro outdoorové akční sporty.',
    region_error_message: 'je třeba vybrat oblast',
    country_error_message: 'je třeba vybrat zemi',
    state_error_message: 'je třeba vybrat kraj',
    config_error_message: 'Při otevírání tohoto formuláře došlo k chybě kvůli chybějícímu nastavení správy.',
    success: 'Úspěch',
    error: 'Chyba',
    invalid_phone_number: 'Neplatné telefonní číslo',
    invalid_email: 'Neplatný e-mail',
    submit: 'Odeslat',
    success_message: 'Vaše informace byla úspěšně odeslána',
    error_message: 'Při odesílání informace došlo k chybě. Zkuste to znovu, nebo pokud problém přetrvává, kontaktujte podporu.',
    no_schema: 'Tato funkce nebyla konfigurována',
    reset: 'Resetovat',
    quick_entry_table_header_shipment_b2c: 'Zásilka',
    enter_quantities_segmented_control_b2c_by_shipment: 'Podle zásilky',
    new_document_b2c_shipment: 'Zásilka',
    boxed_order: 'Objednávka v krabici',
    boxed_order_explanation_title: 'Objednávky v krabicích',
    boxed_order_explanation: 'Přidání více než jedné krabice do objednávky funguje jako množstevní násobitel. Zadané množství se duplikuje přes # zadaných krabic.',
    boxes: 'Krabice',
    units_per_box: 'Jednotky na krabici',
    regional: 'Regionální',
    purchase_order_tooltip: 'Zadaná hodnota není platná.',
    tournament_date_is_too_late: 'Datum musí být %{date} nebo dřívější',
    tournament_date_is_too_early: 'Datum musí být v den nebo po dni %{date}',
    page_notes_error: 'Zadaná hodnota není platná.',
    order_name_not_valid: 'Zadaná hodnota není platná.',
    internet_connection_lost: 'Ztráta internetového připojení',
    internet_connection_lost_builder_description: '<p>Nejste připojeni k internetu. Chcete-li pokračovat, znovu se připojte. Vaše práce byla uložena do posledního ručního nebo automatického uložení.</p><p>Pokud se obáváte ztráty dat, kliknutím na tlačítko níže si stáhněte záložní datový soubor a přepošlete jej na <Link>support@elasticsuite.com</Link>, abyste mohli obnovit svou objednávku.</p>',
    internet_connection_lost_description: 'V současné době nejste připojeni k internetu. Chcete-li pokračovat, připojte se znovu.',
    download_backup_data_file: 'Stáhnout soubor se záložními daty',
    invalid_session: 'Neplatná relace',
    invalid_session_builder_description: '<p>Nejste připojeni k internetu. Chcete-li pokračovat, znovu se připojte. Vaše práce byla uložena v rámci posledního ručního nebo automatického uložení.</p><p>Pokud se obáváte ztráty dat, kliknutím na tlačítko níže si stáhněte záložní datový soubor a přepošlete jej na <Link>support@elasticsuite.com</Link>, čímž obnovíte svou objednávku.</p>',
    invalid_session_description: 'Nejste připojeni k internetu. Chcete-li pokračovat, znovu se připojte.',
    submit_order_description: 'Vaše objednávka bude právě odeslána. Po odeslání bude uzamčena a nebude možné ji upravovat. Upozorňujeme, že objednávka bude uložena do cloudu.',
    all_sells_final: 'Všechny objednávky událostí jsou konečné a nelze je vrátit. Před odesláním objednávky se ujistěte, že jste ji zkontrolovali. Děkujeme za vaši podporu!',
    invalid_date: 'Neplatné datum',
    date_placeholder: 'MM-DD-RRRR',
  }),
);
