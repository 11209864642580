define(
({
  "no_data_message": "没有记录",
  "order_number": "订单号",
  "elastic_order_number": "Elastic订单#",
  "line_number": "行号",
  "customer_name": "客户姓名",
  "customer_number": "客户编号",
  "location_number": "位置号",
  "location_name": "位置名称",
  "product": "产品编号",
  "style_name": "产品名称",
  "code": "颜色代码",
  "color": "颜色名称",
  "status": "行状态",
  "states": "订单状态",
  "tracking": "跟踪号",
  "parcel_number": "包裹#",
  "year": "年",
  "season": "季节",
  "order_date": "创建日期",
  "cancel_date": "按日期取消",
  "invoice_date": "发票日期",
  "invoice": "发票号",
  "invoice_url": "发票网址",
  "download": "下载",
  "view": "查看",
  "ship_date": "请求的配送日期",
  "expected_ship_date": "预计配送日期",
  "terms_name": "条款名",
  "po_number": "订单号",
  "size": "尺寸",
  "alt_name": "尺寸长度",
  "upc": "UPC",
  "units": "数量",
  "unit_singular": "单元",
  "unit_plural": "单元",
  "amount": "金额",
  "net_price": "净",
  "unit_price": "单价",
  "orders": "订单",
  "totals": "总计",
  "total": "总计",
  "details": "详细信息",
  "open": "开放",
  "shipped": "已发货",
  "cancelled": "已取消",
  "canceled": "已取消",
  "released": "已发布",
  "invoiced": "已开发票",
  "picked": "已挑选",
  "region": "地区",
  "order_type": "订单类型",
  "backorder_date": "延期交货日期",
  "backorder": "延期交货",
  "payment_terms": "支付期限",
  "ship_via": "配送方式",
  "line_discount": "行折扣",
  "rma_number": "RMA #",
  "cash_discount": "现金折扣",
  "hold_for_confirmation": "等待确认",
  "credit_hold": "持有信用",
  "order_notes": "订单备注",
  "product_series": "产品系列",
  "product_category": "产品类别",
  "product_sub_category": "产品子类别",
  "quantity_confirmed": "已确认",
  "quantity_backordered": "延期交货",
  "quantity_cancelled": "已取消",
  "quantity_shipped": "已发货",
  "ivendix_order_id": "iVendix ID",
  "catalog": "目录",
  "source": "来源",
  "division": "分区",
  "actual_ship_date": "实际送日期",
  "rep_name": "代表名字",
  "tracking_carrier": "承运商代码",
  "gross_amount": "总额",
  "discount_percent": "折扣%",
  "internal_code": "内部代号",
  "requested_date": "请求日期",
  "net_amount_total": "净总金额",
  "freight_amount": "货物金额",
  "client_order_status": "订单状态",
  "ship_address": "配送地址",
  "ship_city": "配送城市",
  "ship_state": "配送状态",
  "warehouse_name": "仓库名称",
  "client_carrier_code": "客户承运商代码",
  "discount_1": "折扣1",
  "discount_2": "折扣2",
  "discount_3": "折扣3",
  "brand": "品牌",
  "quantity_open": "未配货数量",
  "amount_open": "未配货金额",
  "quantity_invoiced": "已开发票数量",
  "amount_invoiced": "已开发票金额",
  "promo_code": "促销码",
  "size_code": "尺寸码",
  "due_date": "到期日期",
  "quantity_ordered": "下单数量",
  "quantity_allocated": "分配数量",
  "available_date": "有货日期",
  "bank_information": "银行信息",
  "shipment_number": "配送编号",
  "quantity_shipped_sku": "已发货SKU",
  "name": "名称",
  "customs_reference": "海关参考号",
  "currency_code": "货币代号",
  "id_model_partenaire_1": "合作伙伴模型ID",
  "id_model_partenaire_2": "合作伙伴模型ID 2",
  "expected_ship_date_range": "预计配送日期区间"
})
);
