define(
  ({
    no_data_message: 'Ingen poster',
    order_number: 'ERP-bestillingsnummer',
    elastic_order_number: 'Elastic bestillingsnr.',
    line_number: 'Linjenr.',
    customer_name: 'Kundenavn',
    customer_number: 'Kundenr.',
    location_number: 'Stedsnr.',
    location_name: 'Stedsnavn',
    product: 'Produktnr.',
    style_name: 'Produktnavn',
    code: 'Fargekode',
    color: 'Fargenavn',
    status: 'Linjestatus',
    states: 'Bestillingsstatus',
    tracking: 'Sporingsnr.',
    parcel_number: 'Pakkenr.',
    year: 'År',
    season: 'Sesong',
    order_date: 'Opprettet den',
    cancel_date: 'Kansellert etter dato',
    invoice_date: 'Fakturadato',
    invoice: 'Fakturanr.',
    invoice_url: 'Faktura-URL',
    download: 'Last ned',
    view: 'Vis',
    ship_date: 'Forespurt leveringsdato',
    expected_ship_date: 'Forventet forsendelsedato',
    terms_name: 'Vilkårsnavn',
    po_number: 'PO-nummer',
    size: 'Størrelse',
    alt_name: 'Størrelse – Lengde',
    upc: 'UPC',
    units: 'Antall',
    unit_singular: 'Enhet',
    unit_plural: 'Enheter',
    amount: 'Antall',
    net_price: 'Netto',
    unit_price: 'Enhetspris',
    orders: 'Bestillinger',
    totals: 'Totaler',
    total: 'Totalt',
    details: 'Detaljer',
    open: 'Åpen',
    shipped: 'Sendt',
    cancelled: 'Avbrutt',
    canceled: 'Avbrutt',
    released: 'Utgitt',
    invoiced: 'Fakturert',
    picked: 'Plukket',
    region: 'Region',
    order_type: 'Bestillingstype',
    backorder_date: 'Restbestillingsdato',
    backorder: 'Restbestilling',
    payment_terms: 'Betalingsbetingelser',
    ship_via: 'Sendes via',
    line_discount: 'Linjerabatt',
    rma_number: 'RMANR.',
    cash_discount: 'Kontantrabatt',
    hold_for_confirmation: 'Hold for bekreftelse',
    credit_hold: 'Kreditthold',
    order_notes: 'Bestillingsmerknader',
    product_series: 'Produktserie',
    product_category: 'Produktkategori',
    product_sub_category: 'Produktunderkategori',
    quantity_confirmed: 'Bekreftet',
    quantity_backordered: 'Restbestillinger',
    quantity_cancelled: 'Avbrutt',
    quantity_shipped: 'Sendt',
    ivendix_order_id: 'iVendix ID',
    catalog: 'Katalog',
    source: 'Kilde',
    division: 'Inndeling',
    actual_ship_date: 'Faktisk forsendelsedato',
    rep_name: 'Representantnavn',
    tracking_carrier: 'Transportørkode',
    gross_amount: 'Bruttobeløp',
    discount_percent: 'Rabatt %',
    internal_code: 'Intern kode',
    requested_date: 'Forespurt dato',
    net_amount_total: 'Totalt nettobeløp',
    freight_amount: 'Fraktbeløp',
    client_order_status: 'Bestillingsstatus',
    ship_address: 'Fraktadresse',
    ship_city: 'Fraktby',
    ship_state: 'Fraktstat',
    warehouse_name: 'Lagernavn',
    client_carrier_code: 'Transportørkode for kunde',
    discount_1: 'Rabatt 1',
    discount_2: 'Rabatt 2',
    discount_3: 'Rabatt 3',
    brand: 'Merke',
    quantity_open: 'Antall åpen',
    amount_open: 'Beløp åpen',
    quantity_invoiced: 'Antall fakturert',
    amount_invoiced: 'Beløp aktivert',
    promo_code: 'Kampanjekode',
    size_code: 'Størrelsekode',
    due_date: 'Forfallsdato',
    quantity_ordered: 'Antall bestilt',
    quantity_allocated: 'Antall fordelt',
    available_date: 'Tilgjengelig dato',
    bank_information: 'Bankinformasjon',
    shipment_number: 'Forsendelsenr.',
    quantity_shipped_sku: 'Frakt-SKU',
    name: 'Navn',
    customs_reference: 'Kundereferanse',
    currency_code: 'Valutakode',
    id_model_partenaire_1: 'Partnermodell-ID',
    id_model_partenaire_2: 'Partnermodell-ID 2',
    expected_ship_date_range: 'Forventet datoområde for forsendelse',
  }),
);
