
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "id": "elasticScramble",
  "name": "Scramble",
  "description": "Test implementation of scramble in MVC framework.",
  "splash": "splash",
  "useConfigOrder": true,
  "loaderConfig": {
    "paths": {
      "scramble": "./scramble",
      "app": "./app"
    }
  },
  "dependencies": [
  ],
  "flags": {
    "paymetricTemplateName": "creditcarsdCVVAddress",
                                                              
                  
                     
                             
                                   
                         
           
                        
                             
                                   
                         
          
         
    "embellishmentStrategies": [],
    "embellishmentsActionableForRepsOnly": false,
                                                                              
    "usesExternalOrderHistory": false,
    "usesExternalPrices": false,
    "enableStrikethrough": false,
    "warnOfZeroQuantityPageProductsAtSubmission": false,
    "warnZeroQuantityPageProductsAtSubmission" : false,
    "showExternalPriceWhenEqualToWholesalePrice": false,
    "externalPriceTotalsShouldFallBackToWholesalePrice": false,
    "restrictShipToByDivision": false,
    "hideNotOrderable": false,
    "hideSalesProgramSummaryPage": false,
    "filterNotOrderable": false,
                                                        
                                        
                        
                                 
    "undoLimit": 30,
    "mediaDump": {
      "alternateImageSize": "large",
      "includesHighResOption": false,
      "hiresimages": ["primary"]
    },
    "clientFieldsCss": {
                                       
                                      
                                          
                                        
    },
                      
                                                       
                                                                           
                           
                    
                                                                       
                                                               
             
                    
                                                                     
                                                                
            
           
                                                                                       
                                                   
           
                                                                              
         
    "dropShip": {
      "alwaysEnabled": false,
      "enableThirdAddressLine": false,
      "requireLocation": false,
      "availableCountries": null,
                                
      "additionalFields": [],
      "internationalStateRequired": false,                                
      "warning": {
        "display": "none"
                                        
      },
      "additionalMessageByRegion" : false,
                             
                               
                                   
                                   
                               
                                
                             
           
                          
                                      
                                          
                                          
                                      
                                       
                                    
          
      "ivendixRequireLocation": false,
      "limitToCustomerCountry": false
    },
    "showSubmissionState": false,
    "smartystreets_web_key": "10500048659274149",
                          
                                            
                                              
                                            
                                          
                                         
                                                                               
                                   
                                                                                           
                    
                    
                                                                        
             
                       
                                                                    
                                                       
                                                                  
                                                     
                                                                         
                                                       
                                                        
                                                     
                                                             
            
          
         
                     
                          
    "showPrintAtsDateOption": false,
                         
                                
    "poRequired": true,
    "hidePurchaseOrderNumber": false,
                                                                                     
    "availabilityWarnings": false,
    "availabilityRestrictsValidity": false,
    "enforceVariationAvailableOnForAvailability": false,
    "zeroQuantityProductsAreAvailable": false,
    "displayContractInventory": false,
    "hideWarehousesWithZeroInventory": false,
    "integrationFields": null,
                             
                                                        
                                                                       
          
                          
                            
                               
                                            
                                                
                      
                                                             
                                                               
            
           
          
                            
                                
                                                                       
                                            
                                                
                                                                                                                                 
                      
                                                             
                                                               
            
           
          
                              
                                   
                                               
                                                   
                     
                                                                 
                                                 
            
           
          
                             
                                  
                                              
                                                  
                           
                              
                            
            
          
         
                                                      
                                                              
                                                    
    "exportAvailability": false,                                          
                                       
    "importCsv": true,
    "discounts": false,
                             
                        
        
    "privacyPolicyLink": "https://elasticsuite.freshdesk.com/support/solutions/articles/5000775464-plumriver-llc-privacy-policy",
    "loginPageText":{
      "customizedText": "",
      "customizedURL": ""
    },
    "forceDiscountConfirmation": false,
    "truncateSizesLength": 6,
    "pageLevelDiscounts": false,
    "orderLevelDiscounts": false,
    "forceDiscountSelection": false,
    "suppressCheckout": false,
    "allowTransfer": false,
    "enterQuantities": false,
    "disableQuantityInput" : false,
    "hideAllProductsButton": false,
    "hideMoreDetailsButton": false,
    "hideSortDropdownInCartOverview": false,
    "hideEditIconsInCartOverview": false,
    "hasProductDetailIn3upView": false,
    "hideSizeLabelIn3upView": false,
    "hide1upAnd3upButtons": false,
    "showTheColorInfoIn3upView": {
      "colorName": false,
      "colorCode": false
    },
    "dropdownOptionsConfig": {
      "workbook": true,
      "name": true,
      "price": true,
      "number": true,
      "inventory": true,
      "relevance_order": true,
      "color_code": false
    },
    "inventoryCheck": false,
    "inventoryCheckOnProceedToCheckout": false,
    "includeOTSColumn": false,
    "includeCustomerNumberInSelect": true,
    "includeLocationNumberInSelect": false,
    "separateInfinityStyle": false,
    "hideRegister": false,                     
    "requireDealerTaxLicenseIdOnRegister": false,
                         
                                         
         
    "hideProductSearch": false,
    "altNameSeparator": " ",
    "filters": {"gender": {"hide": false}},
    "defaultBuilderMode": "advanced",
    "defaultBuilderModeForReps": "advanced",
    "defaultBuilderForAtsCatalog": "advanced",
    "defaultBuilderForPrebookCatalog": "advanced",
    "assetManager": false,
    "whiteboard": {
                                                          
      "logoHeight": 34,
      "logoWidth": 134
    },
    "customCatalogBinding": false,
    "customCatalogCrop": false,
    "customCatalogDuplicateProducts": false,
    "customCatalogDefaultPaperSize": "LETTER",
    "pageLevelNotes": false,
    "pageNotesMaxLength": null,
    "pageNotesRegExp": "^[a-zA-Z0-9\\s]*$",
    "documentLevelNotes": true,
    "documentNotesMaxLength": null,                                 
    "documentNotesRegExp": "^[a-zA-Z0-9\\s]*$",
                               
                        
                       
          
                         
                        
         
    "atsLabelMax": 99,
    "atsLabelMaxRep": null,
    "constrainArriveOnByLastShip": false,
    "noDefaultCancelOn": false,
    "importClientFile": false,                                                                               
    "importOfflineOrder": false,
    "defaultOrderDensity": "compact",
    "exportPdfDefaults": {
                               
                              
                                     
                                  
                               
                               
                            
                            
                           
    },
                                                                                          
                             
                          
                         
                            
        
                                               
    "catalogBrands": null,
    "orderStatus": {
                                                                       
                                                                                           
                                                                                        
                             
                              
                               
         
                                  
                                 
                                                                                                       
                                                                             
                                                    
      "hideUpdate": true,
      "hideCancel": true,
      "trackingNumberIsClickable": true
    },
                                    
    "atsLogic": "catalog",
    "atsAllowFuture": false,
    "hideCatalogPositionOption": false,                                         
    "defaultLandingPageReps": "explore",                               
    "defaultLandingPageRetailer": "explore",                                    
    "showBrands": false,
    "pageBreak": true,
    "hideLoginFields": false,
    "hideElasticSupportLink": false,
    "missingImage": "scramble/resources/images/missing.svg",
    "showInvoiceAfterSubmit": false,
    "showInvoiceBeforeSubmit": false,
    "documentsView": {
      "showErpNumber": false,
      "mediaDump": true,
      "openInvoiceOnSelect": true,
      "exportPdf": false
                               
                                          
                                   
                                          
          
    },
                                                                                                 
                                                  
                                                                                                                                                                                                               
    "productBrowserTag": "Workbook",
    "externalBarcodeApi": false,
                         
            
                                                                                                    
                                                                                                                                                                          
                                                                                                                                                         
                                                                                                              
                                                                          
             
            
                                       
                                 
                          
                            
                           
             
            
                                       
                                
                                    
                            
                           
             
            
                                       
                                        
                                 
                            
                           
             
            
                                       
                                        
                                 
                            
                          
             
            
                                  
                           
                           
                          
            
           
                                 
         
                                 
                       
         
        
    "i18n": {
      "erp_order_number": "nls:erp_order_number",
      "product_data_sheet_tab_technology": "nls:product_data_sheet_tab_technology"
    },
                        
                                                 
                                                           
        
                           
                               
                                                    
         
                           
                                  
                                                                                                 
         
                            
                               
                                                                                                                
         
    "googleAnalyticsTrackingId": "UA-114434126-2",
                               
                          
          
                              
                                      
                                                                                                   
                         
                                      
                   
                                                                          
                                                                          
                                                                          
                                                                            
                                                                             
                                                                          
                                                                         
         
       
    "onlyCustomerTags": null,
    "exceptCustomerTags": null,
    "onlyCatalogTags": null,
    "exceptCatalogTags": null,
    "secondaryCatalogTags": [["Catalog Type"]],
    "newOrderDefaultCatalogTags": [["Catalog Type", "Standard"]],
    "tertiaryCatalogTags": null,
    "forceCustomerSelection": false,
    "usesCatalogTagFilters": false,
    "walkMeGUID": null,
    "slideshowView": false,
    "forecastingAllowedUserTypes": ["rep"],
    "collectionsPrintDefaultPreferences": {
      "categories": true,
      "atsDate": false,
      "includeWholePrices": true,
      "includeRetailPrices": false,
      "doubleSided": true,
      "emailMe": true,
      "highDPI": false
    },
    "hideProductDataInUI": {
      "product_number": false,
      "variation_name": false,
      "variation_number": false,
      "stock_item_name": false
    },
    "EnableCartDetailInventoryWarning": false,
    "hideOrderPriceInCartDetailView": false,
    "productImportTemplates": [],
    "defaultViewForRepUser": "view" ,
    "defaultViewForDealerUser": "select",
    "skipNewOrderFormModal": false,
                                    
                                                             
    "apolloClientOptions": {
      "uri": "https://graphql.contentful.com/content/v1/spaces/63bwzl6uxi7r",
      "bearerToken": null
    },
    "tenantId": null,
    "directoryMode": false,
    "viewProducts": true,
    "algoliaConfig": null,
    "brandSummaryTag": "Product Categories",
    "searchResultsFilteredByAvailability": {
      "enabled": false,
      "availabilityRanges": [
        {"label": "2 weeks", "daysWithin": 14},
        {"label": "Any future date", "daysWithin": 0},
        {"label": "No restriction", "daysWithin": 0}
      ]
    },
    "hasBrandDetails": false,
    "disableErpSelectActions": false,
    "showOrderTypeModal": true,
    "hideAccountSettings": false,
    "thoughtspotSettings": {
      "url": null,
      "repLiveboardId": null,
      "dealerLiveboardId": null
    },
    "shipmentsByAvailableStock": false,
    "showZeroValueDiscounts": false,
    "requireEmbellishedProductsToBeEmbellished": false,
    "includeOptionToExportFutureAvailability": false,
    "skipAddressValidation": false,
    "enableCopyUrlonOrdersAndCollections": false
  },
                                                              
                      
              
                       
         
                 
                          
         
                 
                       
        
       
  "modules": [],
  "controllers": [
    "dojox/app/controllers/History",
    "dojox/app/controllers/Layout",
    "dojox/app/controllers/Transition",
    "dojox/app/controllers/Load",
    "scramble/ConnectionMonitor",
    "scramble/PageSetupController",
    "scramble/TitleManager",
    "scramble/CmsAdminController",
    "scramble/ZendeskWidgetController",
    "scramble/SalesForceChatBotController",
    "scramble/GenesysChatBotController"
  ],
  "stores": {},
  "models": {},
  "desktopExtension": "els",
  "genericDesktopExtension": "els",
  "environments": {
    "default": {
      "server": ".*",
      "apiPath": "/api/"
    },
    "test": {
      "server": "127.0.0.1",
      "apiHost": "127.0.0.1:56401"
    }
  },
  "defaultView": "splash",
  "defaultTransition": "fade",
  "defaultPriceGroup": "wholesale",
  "has": {
    "tablet": {
      "defaultTransition": "none",
      "views": {
        "dashboard": {
          "defaultTransition": "none"
        },
        "splash": {
          "defaultTransition": "none"
        },
        "builder": {
          "defaultTransition": "none"
        }
      }
    }
  },
  "views": {
    "splash": {
      "type": "scramble/views/Splash",
      "defaultView": "login",
      "defaultTransition": "fade",
      "views": {
        "login": {
          "type": "scramble/views/splash/Login",
          "name": "login",
          "defaultView": "blank",
          "defaultTransition": "none",
          "views": {
            "blank": {
              "type": "scramble/views/Blank",
              "name": "blank"
            },
            "resetpassword": {
              "type": "scramble/views/splash/ResetPassword",
              "name": "resetpassword"
            }
          }
        },
        "register": {
          "type": "scramble/views/splash/Register",
          "defaultTransition": "none"
        },
        "signup": {
          "type": "scramble/views/splash/DealerRequest",
          "defaultTransition": "none"
        },
        "unsubscribe": {
          "type": "scramble/views/splash/Unsubscribe",
          "defaultTransition": "none"
        }
      }
    },

    "dashboard": {
      "type": "scramble/views/Dashboard",
      "defaultView": "home",
      "defaultTransition": "fade",
      "views": {
        "home": {
          "type": "scramble/views/dashboard/Home",
          "name": "home"
        },
        "campaigns": {
          "type": "scramble/views/dashboard/Campaigns",
          "name": "campaigns"
        },
        "ondemand": {
          "type": "scramble/views/dashboard/OnDemand",
          "name": "ondemand"
        },
        "newDocument": {
          "type": "scramble/views/dashboard/NewOrder",
          "name": "newDocument",
          "exploreAlias": "explore,newOrder"
        },
        "newCustomCatalog": {
          "type": "scramble/views/dashboard/NewCustomCatalog",
          "name": "newCustomCatalog",
          "exploreAlias": "explore,newCollection"
        },
        "orderHistory": {
          "type": "scramble/views/dashboard/OrderStatus",
          "name": "orderHistory",
          "defaultView": "blank",
          "exploreAlias": "explore,orderHistory",
          "views": {
            "blank": {
              "type": "scramble/views/Blank",
              "name": "blank"
            },
            "chart": {
              "type": "scramble/views/dashboard/orderStatus/Chart",
              "name": "chart"
            }
          }
        },
        "externalOrderHistory": {
          "type": "scramble/views/dashboard/ExternalOrderHistory",
          "name": "externalOrderHistory"
        },
        "documents": {
          "type": "scramble/views/dashboard/Documents",
          "name": "documents",
          "exploreAlias": "explore,orders"
        },
        "whiteboards": {
          "type": "scramble/views/dashboard/Whiteboards",
          "name": "whiteboards",
          "exploreAlias": "explore,whiteboards"
        },
        "openFromDesktop": {
          "type": "scramble/views/dashboard/OpenFromDesktop",
          "name": "openFromDesktop"
        },
        "openFromDealer": {
          "type": "scramble/views/dashboard/OpenFromDealer",
          "name": "openFromDealer"
        },
        "customCatalogs": {
          "type": "scramble/views/dashboard/CustomCatalogs",
          "name": "customCatalogs",
          "exploreAlias": "explore,collections"
        },
        "catalogs": {
          "type": "scramble/views/dashboard/Catalogs",
          "name": "catalogs"
        },
        "printJobs": {
          "type": "scramble/views/dashboard/PrintJobs",
          "name": "printJobs",
          "exploreAlias": "explore,printJobs"
        },
        "assetManager": {
          "type": "scramble/views/dashboard/AssetManager",
          "name": "assetManager"
        },
        "userManager": {
          "type": "scramble/views/dashboard/UserManager",
          "name": "userManager"
        },
        "accountSettings": {
          "type": "scramble/views/dashboard/AccountSettings",
          "name": "accountSettings",
          "exploreAlias": "explore,accountSettings"
        },
        "links": {
          "type": "scramble/views/dashboard/Links",
          "name": "links"
        },
        "search": {
          "type": "scramble/views/dashboard/SearchView",
          "name": "search"
        },
        "reportOrders": {
          "type": "scramble/views/dashboard/ReportOrders",
          "name": "reportOrders"
        }
      }
    },

    "explore": {
      "type": "scramble/views/Explore",
      "defaultView": "home",
      "defaultTransition": "none",
      "transition": "none",
      "views": {
        "home": {
          "type": "scramble/views/explore/Explore",
          "name": "home"
        },
        "dashboard": {
          "type": "scramble/views/dashboard/Home",
          "name": "dashboard"
        },
        "manage": {
          "type": "scramble/views/explore/Manage",
          "name": "manage"
        },
        "brands": {
          "type": "scramble/views/home/Brands"
        },
        "brandDetails": {
          "type": "scramble/views/home/BrandDetails"
        },
        "catalogList": {
          "type": "scramble/views/home/CatalogList"
        },
        "collectionList": {
          "type": "scramble/views/home/CollectionList"
        },
        "search": {
          "type": "scramble/views/home/Search"
        },
        "campaigns": {
          "type": "scramble/views/dashboard/Campaigns",
          "name": "campaigns"
        },
        "ondemand": {
          "type": "scramble/views/dashboard/OnDemand",
          "name": "ondemand"
        },
        "newOrder": {
          "type": "scramble/views/dashboard/NewOrder",
          "name": "newOrder"
        },
        "newCollection": {
          "type": "scramble/views/dashboard/NewCustomCatalog",
          "name": "newCollection"
        },
        "orderHistory": {
          "type": "scramble/views/dashboard/OrderStatus",
          "name": "orderHistory",
          "defaultView": "blank",
          "hideBrandSelector": true,
          "hideCustomerSelector": true,
          "views": {
            "blank": {
              "type": "scramble/views/Blank",
              "name": "blank"
            },
            "chart": {
              "type": "scramble/views/dashboard/orderStatus/Chart",
              "name": "chart"
            }
          }
        },
        "externalOrderHistory": {
          "type": "scramble/views/dashboard/ExternalOrderHistory",
          "name": "externalOrderHistory"
        },
        "orders": {
          "type": "scramble/views/dashboard/Documents",
          "name": "orders",
          "hideBrandSelector": true
        },
        "whiteboards": {
          "type": "scramble/views/dashboard/Whiteboards",
          "name": "whiteboards"
        },
        "openFromDesktop": {
          "type": "scramble/views/dashboard/OpenFromDesktop",
          "name": "openFromDesktop"
        },
        "openFromDealer": {
          "type": "scramble/views/dashboard/OpenFromDealer",
          "name": "openFromDealer"
        },
        "collections": {
          "type": "scramble/views/dashboard/CustomCatalogs",
          "name": "collections",
          "hideCustomerSelector": true
        },
        "catalogs": {
          "type": "scramble/views/dashboard/Catalogs",
          "name": "catalogs"
        },
        "printJobs": {
          "type": "scramble/views/dashboard/PrintJobs",
          "name": "printJobs",
          "hideBrandSelector": true,
          "hideCustomerSelector": true
        },
        "assetManager": {
          "type": "scramble/views/dashboard/AssetManager",
          "name": "assetManager"
        },
        "userManager": {
          "type": "scramble/views/dashboard/UserManager",
          "name": "userManager"
        },
        "accountSettings": {
          "type": "scramble/views/dashboard/AccountSettings",
          "name": "accountSettings"
        },
        "links": {
          "type": "scramble/views/dashboard/Links",
          "name": "links"
        },
        "reportOrders": {
          "type": "scramble/views/dashboard/ReportOrders",
          "name": "reportOrders"
        },
        "reportInventory": {
          "type": "scramble/views/dashboard/ReportInventory",
          "name": "reportInventory"
        },
        "metrics": {
          "type": "scramble/views/dashboard/Metrics",
          "name": "metrics"
        }
      }
    },

    "builder": {
      "type": "scramble/views/Builder",
      "defaultView": "select",
      "views": {
        "view": {
          "type": "scramble/views/builder/Browse",
          "name": "view",
          "defaultTransition": "slide"
        },
        "select": {
          "type": "scramble/views/builder/Order",
          "name": "select",
          "defaultTransition": "slide"
        },
        "order": {
          "type": "scramble/views/builder/Grid",
          "name": "order",
          "defaultTransition": "slide",
          "simpleOnly": true
        },
        "quantities": {
          "type": "scramble/views/builder/Sizing",
          "name": "quantities",
          "defaultTransition": "slide"
        },
        "salesprograms": {
          "type": "scramble/views/builder/SalesPrograms",
          "name": "salesprograms",
          "defaultTransition": "slide",
          "skipButtonCreation": true,
          "modal": true
        },
        "cart": {
          "type": "scramble/views/builder/Cart",
          "name": "cart",
          "defaultTransition": "slide",
          "defaultView": "overview",
          "skipButtonCreation": true,
          "views": {
            "overview": {
              "type": "scramble/views/builder/Overview",
              "name": "overview",
              "defaultTransition": "slide"
            },
            "detail": {
              "type": "scramble/views/builder/Detail",
              "name": "detail",
              "defaultTransition": "slide"
            },
            "review": {
              "type": "scramble/views/builder/Summary",
              "name": "review",
              "defaultTransition": "slide"
            },
            "salesprograms": {
              "type": "scramble/views/builder/SalesPrograms",
              "name": "salesprograms",
              "defaultTransition": "slide"
            },
            "checkout": {
              "type": "scramble/views/builder/Purchase",
              "name": "checkout",
              "defaultTransition": "slide"
            },
            "quantities": {
              "type": "scramble/views/builder/Sizing",
              "name": "quantities",
              "defaultTransition": "slide"
            }
          }
        },
          
                                                                                                            
                                                                                                               
                                                                                                                  
          
        "whiteboard": {
          "type": "scramble/views/builder/Whiteboard",
          "name": "whiteboard",
          "skipButtonCreation": true,
          "defaultTransition": "slide"
        }
      }
    },
    "customCatalogBuilder": {
      "type": "scramble/views/customCatalog/Builder",
      "name": "customCatalogBuilder",
      "defaultView": "editor",
      "views": {
        "editor": {
          "type": "scramble/views/customCatalog/builder/Editor",
          "name": "editor"
        }
      }
    },
    "prePopulatePOWithOrderName": false
  }
}
