define(
  ({
    no_data_message: 'Aucune entrée',
    order_number: 'Commande #',
    elastic_order_number: 'Commande Elastic #',
    line_number: 'Nº de ligne',
    customer_name: 'Nom du client',
    customer_number: 'Client #',
    location_number: 'N° de Livraison',
    location_name: "Nom de l'emplacement",
    product: 'Produit #',
    style_name: 'Nom du produit',
    code: 'Code couleur',
    color: 'Nom de la couleur',
    status: 'État de la ligne',
    states: 'État de la commande',
    tracking: 'N° de suivi',
    parcel_number: 'Colis #',
    year: 'Année',
    season: 'Saison',
    order_date: 'Date de création',
    cancel_date: 'Annuler par date',
    invoice_date: 'Date de facture',
    invoice: 'N° de facture',
    invoice_url: 'URL de la facture',
    download: 'Télécharger',
    view: 'Voir',
    ship_date: "Date d'expédition demandée",
    expected_ship_date: "Date d'expédition prévue",
    terms_name: 'Nom des conditions',
    po_number: 'BC #',
    size: 'Taille',
    alt_name: 'Longueur de la taille',
    upc: 'CUP',
    units: 'Qté',
    unit_singular: 'Unité',
    unit_plural: 'Unités',
    amount: 'Montant',
    net_price: 'Net',
    unit_price: 'Prix unitaire',
    orders: 'Commandes',
    totals: 'Total',
    total: 'Total',
    details: 'Détails',
    open: 'Ouvrir',
    shipped: 'Expédié',
    cancelled: 'Annulé',
    canceled: 'Annulé',
    released: 'Publié',
    invoiced: 'Facturé',
    picked: 'Sélectionné',
    region: 'Région',
    order_type: 'Type de commande',
    backorder_date: 'Date de la commande en attente',
    backorder: 'Commande en attente',
    payment_terms: 'Conditions de paiement',
    ship_via: 'Expédier via',
    line_discount: 'Réduction de ligne de commande client',
    rma_number: "Numéro d'accord de retour",
    cash_discount: 'Réduction au comptant',
    hold_for_confirmation: 'Retenu pour confirmation',
    credit_hold: 'Recouvrement de crédit',
    order_notes: 'Notes de la commande',
    product_series: 'Série de produit',
    product_category: 'Catégorie de produit',
    product_sub_category: 'Sous-catégorie de produit',
    quantity_confirmed: 'Confirmé',
    quantity_backordered: 'En cours de réapprovisionnement',
    quantity_cancelled: 'Annulé',
    quantity_shipped: 'Expédié',
    ivendix_order_id: 'ID iVendix',
    catalog: 'Catalogue',
    source: 'Source',
    division: 'Division',
    actual_ship_date: "Date d'expédition effective",
    rep_name: 'Nom de réprésentant',
    tracking_carrier: 'Code de transporteur',
    gross_amount: 'Montant brut',
    discount_percent: '% de la réduction',
    internal_code: 'Code interne',
    requested_date: 'Date de demande',
    net_amount_total: 'Montant total net',
    freight_amount: 'Montant de transport',
    client_order_status: 'État de la commande',
    ship_address: 'Adresse de livraison',
    ship_city: 'Ville de livraison',
    ship_state: 'Département de livraison',
    warehouse_name: "Nom de l'entrepôt",
    client_carrier_code: 'Code de transporteur du client',
    discount_1: 'Remise 1',
    discount_2: 'Remise 2',
    discount_3: 'Remise 3',
    brand: 'Marque',
    quantity_open: 'Quantité ouverte',
    amount_open: 'Montant ouvert',
    quantity_invoiced: 'Quantité facturée',
    amount_invoiced: 'Montant facturé',
    promo_code: 'Code promo',
    size_code: 'Code de taille',
    due_date: 'Date butoir',
    quantity_ordered: 'Quantité commandée',
    quantity_allocated: 'Quantité allouée',
    available_date: 'Date de disponibilité',
    bank_information: 'Informations bancaires',
    shipment_number: 'Livraison #',
    quantity_shipped_sku: 'SGU expédié',
    name: 'Nom',
    customs_reference: 'Référence douanière',
    currency_code: 'Code de devise',
    id_model_partenaire_1: 'ID de modèle de partenaire',
    id_model_partenaire_2: 'ID de modèle de partenaire 2',
    expected_ship_date_range: "Plage de dates prévue de l'expédition",
  }),
);
